import { Box } from "@mui/material";
import { ThemeImageIcon } from "@/assets/images";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  setOpenGlobalFontFamily,
  setOpenGlobalPallets,
  setOpenGlobalTheme,
} from "@/redux/slices/toolsSlice";
import { setProductionModel } from "@/redux/slices/userSlice";

export default function ThemeMenu() {
  const dispatch = useAppDispatch();
  const { openGlobalTheme } = useAppSelector((state) => state.tools);

  const handleClick = () => {
    dispatch(setOpenGlobalFontFamily(false));
    dispatch(setProductionModel(false));
    dispatch(setOpenGlobalPallets(false));
    dispatch(setOpenGlobalTheme(true));
  };

  return (
    <Box>
      <Box
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent:"center",
          height: "48px",
          width: "48px",
          padding: "6px",
          borderRadius: "8px",
          backgroundColor: openGlobalTheme ? "#CED4DA" : "transparent",
          ":hover": { backgroundColor: "#CED4DA" },
          transition: "all .3s",
        }}
      >
        <img
          src={ThemeImageIcon}
          alt="Layout"
          style={{ marginRight: "6px", width: "24px", height: "24px" }}
        />
      </Box>
    </Box>
  );
}
