import { PrimaryDoubleTickIcon, WhiteDoubleTickIcon } from "@/assets/images";
import { useAppSelector } from "@/redux/store";
import { Box, Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const PricingSection = () => {
  const navigate = useNavigate();
  const token = useAppSelector((state) => state.auth.auth.accessToken);
  const { t } = useTranslation();

  const motionSettings = {
    initial: { opacity: 0, y: 100 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 2, type: "spring" },
  };

  return (
    <Box sx={{ my: { xs: "2.4rem", sm: "2.8rem", md: "3.4rem" } }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          mb: "40px",
        }}
        component={motion.div}
        transition={{ ...motionSettings.transition, delay: 1.6 }}
        {...motionSettings}
      >
        <Typography
          variant="h2"
          textAlign={"center"}
          sx={{
            fontSize: { xs: "24px", sm: "30px", md: "36px" },
            width: { xs: "90%", sm: "60%", md: "50%", lg: "40%" },
          }}
        >
          {t("Pricing.Powerful_Features_for")}
          <span style={{ color: "#7C4CE1" }}>
            {" "}
            {t("Pricing.Powerful_Creators")}
          </span>
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontFamily: "400", mt: { xs: "12px", sm: "16px", md: "20px" } }}
        >
          {t("Pricing.Pricing_built")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          gap: "40px",
        }}
      >
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 2, type: "spring" }}
          sx={{
            height: "100%",
            minHeight: { xs: "100%", md: "524px" },
            minWidth: { xs: "100%", sm: "100%", md: "390px", lg: "431px" },
            border: "1px solid #DFE4EA",
            padding: { xs: "24px", sm: "40px" },
            borderRadius: "24px",
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontSize: { xs: "30px", sm: "30px", md: "40px" } }}
          >
            {t("Pricing.Free")}
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: "12px", mt: "6px" }}>
            {t("Pricing.Try_it_as_long_as_you_like")}
          </Typography>
          {basicMapData?.map((item, index) => (
            <Box
              component={motion.div}
              initial={{ opacity: 0, x: -300 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: item?.duration, type: "spring" }}
              key={index}
              sx={{ display: "flex", alignItems: "center", mt: "20px" }}
            >
              <img
                src={PrimaryDoubleTickIcon}
                alt="tick"
                style={{ height: "24px", width: "24px" }}
              />
              <Typography
                variant="body1"
                sx={{ fontWeight: "400", ml: "12px", color: "#1E293B" }}
              >
                {t(item?.name)}
              </Typography>
            </Box>
          ))}
          <Button
            variant="outlined"
            sx={PreviewButtonStyle}
            onClick={() => {
              token ? navigate("/create-website") : navigate("/signup");
            }}
          >
            {token ? t("Pricing.Explore") : t("Pricing.Sign_up_for_free")}
          </Button>
        </Box>
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 2, type: "spring" }}
          sx={{
            height: "100%",
            minHeight: { xs: "100%", md: "524px" },
            minWidth: { xs: "100%", sm: "100%", md: "450px", lg: "575px" },
            border: "1px solid #7C4CE1",
            padding: { xs: "24px", sm: "40px" },
            borderRadius: "24px",
            backgroundColor: "#7C4CE1",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {" "}
            <Box>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "30px", sm: "30px", md: "40px" },
                  color: "#fff",
                }}
              >
                {t("Pricing.Pro")}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ mb: "12px", mt: "6px", color: "#fff" }}
              >
                {t("Pricing.Limitless_possibilities")}
              </Typography>
            </Box>
            <Box
              sx={{
                height: { xs: "32px", sm: "40px" },
                backgroundColor: "#fff",
                padding: { xs: "6px 8px", sm: "6px 16px" },
                borderRadius: "88px",
                maxWidth: { xs: "124px", sm: "140px" },
                width: "100%",
              }}
            >
              <Typography variant="caption" sx={{ color: "#000" }}>
                {t("Pricing.COMMING_SOON")}
              </Typography>
            </Box>
          </Box>

          {proMapData?.map((item, index) => (
            <Box
              component={motion.div}
              initial={{ opacity: 0, x: 300 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: item?.duration, type: "spring" }}
              key={index}
              sx={{ display: "flex", alignItems: "center", mt: "20px" }}
            >
              <img
                src={WhiteDoubleTickIcon}
                alt="tick"
                style={{ height: "24px", width: "24px" }}
              />
              <Typography
                variant="body1"
                sx={{ fontWeight: "400", ml: "12px", color: "#fff" }}
              >
                {t(item?.name)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PricingSection;

const basicMapData = [
  { name: "Pricing.Full_access_to", duration: 2 },
  { name: "Pricing.Prebuilt_templates", duration: 2.2 },
  { name: "Pricing.Export_code_for_web", duration: 2.4 },
  { name: "Pricing.Basic_integrations", duration: 2.6 },
  { name: "Pricing.Basic_styling_customization", duration: 2.8 },
  { name: "Pricing.Regular_updates", duration: 3 },
];
const PreviewButtonStyle = {
  borderColor: "#fff",
  mx: "8px",
  border: "2px solid #7C4CE1",
  color: "#7C4CE1",
  width: "100%",
  mt: "40px",
};

const proMapData = [
  { name: "Pricing.Adjust_AI-generated_code", duration: 2 },
  { name: "Pricing.Advanced_integrations", duration: 2.2 },
  { name: "Pricing.Export_code_for_mobile", duration: 2.4 },
  { name: "Pricing.Custom_domain_publishing", duration: 2.6 },
  { name: "Pricing.Early_access_to_beta", duration: 2.8 },
  { name: "Pricing.Export_code_for_mobile", duration: 3 },
  { name: "Pricing.Priority_email_and", duration: 3.2 },
];
