import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppSelector } from "@/redux/store";
import { Container, useTheme } from "@mui/material";
import SecondaryLoader from "./SecondaryLoader";

// ---------Component style------------

const CompletePageLoader = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { appLoader } = useAppSelector((state) => state.services);

  return (
    <Container maxWidth="xl">
      <Dialog
        fullScreen={fullScreen}
        open={appLoader}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialog-paper": {
            height: "100%",
            width: "100%",
            background: "#fff",
            boxShadow: "none",
            boxRadius: "none",
            padding: 1,
            overflowY: "hidden !important",
          },
          "& .MuiDialog-container": {
            background: "#fff",
          },
        }}
      >
        <SecondaryLoader />
      </Dialog>
    </Container>
  );
};

export default CompletePageLoader;
