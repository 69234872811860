import http from "../../services/http.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface deploymentResponse {
  success: boolean;
  message: string;
}

export const deployProject = createAsyncThunk<
  deploymentResponse,
  { projectId: string },
  { rejectValue: any }
>("project/deploy", async (data: { projectId: string }, thunkAPI) => {
  const [response, error] = await http.get(`/project/deploy/${data.projectId}`);
  if (error) {
    return thunkAPI.rejectWithValue(error.resposne.data);
  }
  return response.data;
});
