import { alpha, PaletteMode } from "@mui/material";
// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};

const PRIMARY = {
  lighter: "#a07fff",
  light: "#9366fd",
  main: "#7C4CE1",
  dark: "#6c37cc",
  darker: "#5207aa",
  contrastText: "#fff",
};

const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800],
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#ffa200",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#de1017",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const palette = (mode: PaletteMode) => {
  return {
    ...(mode === "light"
      ? {
          common: { black: "#000", lightBlack: "#090909", white: "#fff" },
          primary: PRIMARY,
          secondary: SECONDARY,
          info: INFO,
          success: SUCCESS,
          warning: WARNING,
          error: ERROR,
          grey: GREY,
          divider: alpha(GREY[500], 0.24),
          text: {
            primary: "#1E293B",
            secondary: "#667085",
            disabled: GREY[500],
          },
          background: {
            paper: "#fff",
            default: "#F9FAFB",
            neutral: "#f1f0f2",
          },
          action: {
            active: GREY[600],
            hover: alpha(GREY[500], 0.08),
            selected: alpha(GREY[500], 0.16),
            disabled: alpha(GREY[500], 0.8),
            disabledBackground: alpha(GREY[500], 0.24),
            focus: alpha(GREY[500], 0.24),
            hoverOpacity: 0.08,
            disabledOpacity: 0.48,
          },
        }
      : {
          common: { black: "#fff", lightBlack: "#090909", white: "#fff" },
          primary: PRIMARY,
          secondary: SECONDARY,
          info: INFO,
          success: SUCCESS,
          warning: WARNING,
          error: ERROR,
          grey: GREY,
          divider: alpha(GREY[500], 0.24),
          text: {
            primary: GREY[100],
            secondary: GREY[300],
            disabled: GREY[400],
          },
          background: {
            paper: "#222222",
            default: "#121212",
            neutral: "#5A5A5A",
          },
          action: {
            active: GREY[400],
            hover: alpha(GREY[500], 0.08),
            selected: alpha(GREY[500], 0.16),
            disabled: alpha(GREY[500], 0.8),
            disabledBackground: alpha(GREY[500], 0.24),
            focus: alpha(GREY[500], 0.24),
            hoverOpacity: 0.08,
            disabledOpacity: 0.48,
          },
        }),
  };
};

export default palette;
