import Dialog from "@mui/material/Dialog";
import { useAppSelector } from "@/redux/store";
import ServerDown from "./ServerDown";
import { Container } from "@mui/material";

// ---------Component style------------

const Maintance = () => {
  const { server } = useAppSelector((state) => state.services);

  return (
    <Container maxWidth="xl" sx={{ height: "100%", width: "100%" }}>
      <Dialog
        fullScreen
        open={server}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialog-paper": {
            height: "100%",
            width: "100%",
            background: "#fff",
            boxShadow: "none",
            boxRadius: "none",
            padding: 1,
            overflowY: "hidden !important",
          },
          "& .MuiDialog-container": {
            background: "#fff",
          },
        }}
      >
        <ServerDown />
      </Dialog>
    </Container>
  );
};

export default Maintance;
