import Dialog from "@mui/material/Dialog";
import { useAppSelector } from "@/redux/store";
import { Container } from "@mui/material";
import AccessForbidden from "./AccessForbidden";

// ---------Component style------------

const ForbiddenScreen = () => {
  const { forbidden } = useAppSelector((state) => state.services);
  return (
    <Container maxWidth="xl" sx={{ height: "100%", width: "100%" }}>
      <Dialog
        fullScreen
        open={forbidden}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialog-paper": {
            height: "100%",
            width: "100%",
            background: "#fff",
            boxShadow: "none",
            boxRadius: "none",
            padding: 1,
            overflowY: "hidden !important",
          },
          "& .MuiDialog-container": {
            background: "#fff",
          },
        }}
      >
        <AccessForbidden />
      </Dialog>
    </Container>
  );
};

export default ForbiddenScreen;
