import Avatar from "@mui/material/Avatar";
import { useAppSelector } from "@/redux/store";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ProfileModel() {
  const ProfileData = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => {
        navigate(ProfileData?.type === "guest" ? "/my-projects" : "/profile");
      }}
      sx={{ my: "12px", cursor: "pointer" }}
    >
      {ProfileData?.profileImage ? (
        <Avatar sx={{ width: 32, height: 32 }}>
          <img
            src={ProfileData?.profileImage}
            alt="profile"
            width="100%"
            height="100%"
          />
        </Avatar>
      ) : (
        <Avatar sx={{ width: 32, height: 32, background: "#7C4CE1" }}>
          {" "}
          {ProfileData?.fullName[0]?.toUpperCase() || "F"}
        </Avatar>
      )}
    </Box>
  );
}
