import React from "react";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import i18next from "i18next";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { setLanguage } from "@/redux/slices/userSlice";
import { LanguageIcon } from "@/assets/images";
import { setDirection } from "@/redux/slices/themeSlice";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface LanguageDropdownProps {}

const LanguageDropdown: React.FC<LanguageDropdownProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { i18nextLng } = useAppSelector((state) => state.user);
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={ImageBoxStyle(location)}
        onClick={handleClick}
        component={"button"}
      >
        <img
          src={LanguageIcon}
          alt="language"
          style={{
            width: "26px",
            height: "26px",
            marginRight: "4px",
          }}
        />
        {location.pathname.startsWith("/icod-builder") ||
        location.pathname === "/" ? null : (
          <Typography
            variant="h6"
            sx={{ fontWeight: "500", display: { xs: "none", sm: "block" } }}
          >
            {t("utalities.Language")}
          </Typography>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-paper": {
            width: "200px",
            p: "6px",
            border: "1px solid  #DFE4EA",
            borderRadius: "8px",
            backgroundColor: "#F9FAFB",
            ":hover": {
              backgroundColor: "#F9FAFB",
            },
          },
        }}
      >
        {mapData?.map((item, index) => (
          <MenuItem
            onClick={() => {
              i18next.changeLanguage(item?.value as string);
              dispatch(setLanguage(item?.value as string));
              dispatch(setDirection(item?.value as string));
              handleClose();
            }}
            key={index}
            value={item?.value}
            sx={{
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {item?.svgSrc}
              <Typography variant="subtitle1" sx={{ ml: "8px" }}>
                {item?.name}
              </Typography>
            </Box>

            {item?.value === i18nextLng && (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_810_46897)">
                  <path
                    d="M3.33398 8.00008L6.66732 11.3334L13.334 4.66675"
                    stroke="#374151"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_810_46897">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageDropdown;

const ImageBoxStyle = (location) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: location.pathname.startsWith("/icod-builder")
    ? "center"
    : "flex-start",
  cursor: "pointer",
  padding: "6px",
  width: "100%",
  border: "none",
  borderRadius: "8px",
  backgroundColor: "transparent",
  transition: "all .3s",
  ":hover": {
    backgroundColor: "#E0DAF9",
  },
});

const mapData = [
  {
    name: "English",
    value: "en",
    svgSrc: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1104_43651)">
          <path
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
            fill="#F0F0F0"
          />
          <path
            d="M11.4785 12.0015H24.0002C24.0002 10.9185 23.8559 9.8692 23.5868 8.87109H11.4785V12.0015Z"
            fill="#D80027"
          />
          <path
            d="M11.4785 5.73983H22.239C21.5044 4.54114 20.5651 3.48162 19.47 2.60938H11.4785V5.73983Z"
            fill="#D80027"
          />
          <path
            d="M12.0001 24.0013C14.8243 24.0013 17.4201 23.0251 19.4699 21.3926H4.53027C6.58012 23.0251 9.17591 24.0013 12.0001 24.0013Z"
            fill="#D80027"
          />
          <path
            d="M1.76146 18.2613H22.2389C22.8286 17.299 23.286 16.2471 23.5868 15.1309H0.413574C0.714371 16.2471 1.17173 17.299 1.76146 18.2613Z"
            fill="#D80027"
          />
          <path
            d="M5.55863 1.87397H6.65217L5.63498 2.61295L6.02353 3.80869L5.00639 3.0697L3.98925 3.80869L4.32487 2.7757C3.42928 3.52172 2.64431 4.39575 1.99744 5.36963H2.34783L1.70034 5.84002C1.59947 6.0083 1.50272 6.17925 1.41 6.35273L1.71919 7.30434L1.14234 6.88523C0.998953 7.18903 0.867797 7.49967 0.749906 7.81678L1.09055 8.86528H2.34783L1.33064 9.60427L1.71919 10.8L0.702047 10.061L0.0927656 10.5037C0.0317812 10.9939 0 11.4932 0 12H12C12 5.37262 12 4.59131 12 0C9.62944 0 7.41961 0.687656 5.55863 1.87397ZM6.02353 10.8L5.00639 10.061L3.98925 10.8L4.3778 9.60427L3.36061 8.86528H4.61789L5.00639 7.66955L5.39489 8.86528H6.65217L5.63498 9.60427L6.02353 10.8ZM5.63498 6.10861L6.02353 7.30434L5.00639 6.56536L3.98925 7.30434L4.3778 6.10861L3.36061 5.36963H4.61789L5.00639 4.17389L5.39489 5.36963H6.65217L5.63498 6.10861ZM10.3279 10.8L9.31073 10.061L8.29359 10.8L8.68214 9.60427L7.66495 8.86528H8.92223L9.31073 7.66955L9.69923 8.86528H10.9565L9.93933 9.60427L10.3279 10.8ZM9.93933 6.10861L10.3279 7.30434L9.31073 6.56536L8.29359 7.30434L8.68214 6.10861L7.66495 5.36963H8.92223L9.31073 4.17389L9.69923 5.36963H10.9565L9.93933 6.10861ZM9.93933 2.61295L10.3279 3.80869L9.31073 3.0697L8.29359 3.80869L8.68214 2.61295L7.66495 1.87397H8.92223L9.31073 0.678234L9.69923 1.87397H10.9565L9.93933 2.61295Z"
            fill="#0052B4"
          />
        </g>
        <defs>
          <clipPath id="clip0_1104_43651">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    name: "العربية",
    value: "ar",
    svgSrc: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1104_43658)">
          <path
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
            fill="#6DA544"
          />
          <path
            d="M6.78271 14.3477C6.78271 15.2121 7.4835 15.9129 8.34792 15.9129H13.0436C13.0436 16.6332 13.6275 17.2172 14.3479 17.2172H15.9131C16.6335 17.2172 17.2175 16.6332 17.2175 15.9129V14.3477H6.78271Z"
            fill="#F0F0F0"
          />
          <path
            d="M17.3477 6.7832V10.4354C17.3477 11.0108 16.8796 11.4789 16.3042 11.4789V13.0441C17.7427 13.0441 18.9129 11.8738 18.9129 10.4354V6.7832H17.3477Z"
            fill="#F0F0F0"
          />
          <path
            d="M6.1304 10.4354C6.1304 11.0108 5.6623 11.4789 5.08691 11.4789V13.0441C6.52537 13.0441 7.6956 11.8738 7.6956 10.4354V6.7832H6.1304V10.4354Z"
            fill="#F0F0F0"
          />
          <path d="M15 6.7832H16.5652V10.4354H15V6.7832Z" fill="#F0F0F0" />
          <path
            d="M12.6519 8.87017C12.6519 9.01403 12.5349 9.13103 12.3911 9.13103C12.2472 9.13103 12.1302 9.01398 12.1302 8.87017V6.7832H10.565V8.87017C10.565 9.01403 10.4479 9.13103 10.3041 9.13103C10.1603 9.13103 10.0433 9.01398 10.0433 8.87017V6.7832H8.47803V8.87017C8.47803 9.87709 9.29721 10.6963 10.3041 10.6963C10.6919 10.6963 11.0516 10.5744 11.3476 10.3674C11.6436 10.5744 12.0033 10.6963 12.3911 10.6963C12.4692 10.6963 12.546 10.6908 12.6215 10.6812C12.5106 11.1382 12.099 11.4789 11.6085 11.4789V13.0441C13.0469 13.0441 14.2172 11.8738 14.2172 10.4354V8.87017V6.7832H12.652V8.87017H12.6519Z"
            fill="#F0F0F0"
          />
          <path
            d="M8.47803 11.4785H10.8259V13.0437H8.47803V11.4785Z"
            fill="#F0F0F0"
          />
        </g>
        <defs>
          <clipPath id="clip0_1104_43658">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    name: "中国人",
    value: "zh",
    svgSrc: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1104_43662)">
          <path
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
            fill="#D80027"
          />
          <path
            d="M6.56719 7.30469L7.60312 10.4922H10.9547L8.24531 12.4656L9.28125 15.6531L6.56719 13.6844L3.85313 15.6531L4.89375 12.4656L2.17969 10.4922H5.53125L6.56719 7.30469Z"
            fill="#FFDA44"
          />
          <path
            d="M14.2265 18.5867L13.4343 17.6117L12.2624 18.0664L12.9421 17.0117L12.1499 16.032L13.364 16.3555L14.0483 15.3008L14.114 16.557L15.3327 16.8805L14.1562 17.3305L14.2265 18.5867Z"
            fill="#FFDA44"
          />
          <path
            d="M15.8015 15.7273L16.1765 14.5273L15.1499 13.8008L16.4062 13.782L16.7765 12.582L17.1843 13.7727L18.4405 13.7586L17.4327 14.5086L17.8358 15.6992L16.8093 14.9727L15.8015 15.7273Z"
            fill="#FFDA44"
          />
          <path
            d="M17.9251 8.8082L17.372 9.93789L18.272 10.8145L17.0298 10.6363L16.4767 11.7613L16.261 10.5238L15.0142 10.3457L16.1298 9.75977L15.9142 8.51758L16.8142 9.39414L17.9251 8.8082Z"
            fill="#FFDA44"
          />
          <path
            d="M14.2592 5.38672L14.1654 6.63828L15.3326 7.11172L14.1092 7.41172L14.0201 8.66797L13.3592 7.59922L12.1357 7.89922L12.9467 6.93828L12.2811 5.87422L13.4482 6.34766L14.2592 5.38672Z"
            fill="#FFDA44"
          />
        </g>
        <defs>
          <clipPath id="clip0_1104_43662">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    name: "Française",
    value: "fr",
    svgSrc: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1104_43666)">
          <path
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
            fill="#F0F0F0"
          />
          <path
            d="M23.9999 11.9997C23.9999 6.84013 20.7434 2.44161 16.1738 0.746094V23.2534C20.7434 21.5578 23.9999 17.1593 23.9999 11.9997Z"
            fill="#D80027"
          />
          <path
            d="M0 11.9997C0 17.1593 3.25655 21.5578 7.82611 23.2533V0.746094C3.25655 2.44161 0 6.84012 0 11.9997Z"
            fill="#0052B4"
          />
        </g>
        <defs>
          <clipPath id="clip0_1104_43666">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    name: "한국어",
    value: "ko",
    svgSrc: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1104_43670)">
          <path
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
            fill="#F0F0F0"
          />
          <path
            d="M16.174 12.0001C16.174 13.0435 14.3052 15.6522 12.0001 15.6522C9.69489 15.6522 7.82617 13.0435 7.82617 12.0001C7.82617 9.69484 9.69489 7.82617 12.0001 7.82617C14.3052 7.82617 16.174 9.69484 16.174 12.0001Z"
            fill="#D80027"
          />
          <path
            d="M16.174 12C16.174 14.3052 14.3052 16.1739 12.0001 16.1739C9.69489 16.1739 7.82617 14.3052 7.82617 12"
            fill="#0052B4"
          />
          <path
            d="M16.4238 15.6895L17.5304 14.5829L18.2682 15.3206L17.1616 16.4272L16.4238 15.6895Z"
            fill="black"
          />
          <path
            d="M14.5791 17.5332L15.6857 16.4266L16.4234 17.1643L15.3168 18.2709L14.5791 17.5332Z"
            fill="black"
          />
          <path
            d="M18.6372 17.9023L19.7438 16.7957L20.4815 17.5335L19.375 18.6401L18.6372 17.9023Z"
            fill="black"
          />
          <path
            d="M16.7925 19.748L17.8991 18.6414L18.6368 19.3792L17.5302 20.4858L16.7925 19.748Z"
            fill="black"
          />
          <path
            d="M17.5303 16.7969L18.6369 15.6903L19.3746 16.428L18.268 17.5346L17.5303 16.7969Z"
            fill="black"
          />
          <path
            d="M15.686 18.6406L16.7926 17.534L17.5304 18.2718L16.4238 19.3784L15.686 18.6406Z"
            fill="black"
          />
          <path
            d="M18.6421 8.3125L15.6911 5.36156L16.4289 4.62382L19.3798 7.57476L18.6421 8.3125Z"
            fill="black"
          />
          <path
            d="M15.6904 7.57422L14.5838 6.46762L15.3215 5.72991L16.4281 6.83651L15.6904 7.57422Z"
            fill="black"
          />
          <path
            d="M17.5352 9.41992L16.4286 8.31332L17.1663 7.57561L18.2729 8.68221L17.5352 9.41992Z"
            fill="black"
          />
          <path
            d="M17.9033 5.36133L16.7967 4.25473L17.5345 3.51699L18.6411 4.62358L17.9033 5.36133Z"
            fill="black"
          />
          <path
            d="M19.749 7.20703L18.6424 6.10043L19.3802 5.36269L20.4868 6.46929L19.749 7.20703Z"
            fill="black"
          />
          <path
            d="M4.25391 16.7949L7.20485 19.7459L6.4671 20.4836L3.51616 17.5327L4.25391 16.7949Z"
            fill="black"
          />
          <path
            d="M7.20508 17.5312L8.31168 18.6378L7.57397 19.3756L6.46737 18.269L7.20508 17.5312Z"
            fill="black"
          />
          <path
            d="M5.36035 15.6875L6.46695 16.7941L5.72921 17.5318L4.62261 16.4252L5.36035 15.6875Z"
            fill="black"
          />
          <path
            d="M6.46729 14.5801L9.41823 17.531L8.68048 18.2688L5.72954 15.3178L6.46729 14.5801Z"
            fill="black"
          />
          <path
            d="M7.20459 4.25195L4.25365 7.20289L3.51591 6.46515L6.46685 3.51421L7.20459 4.25195Z"
            fill="black"
          />
          <path
            d="M8.31152 5.35938L5.36058 8.31032L4.62284 7.57257L7.57378 4.62163L8.31152 5.35938Z"
            fill="black"
          />
          <path
            d="M9.41895 6.4668L6.468 9.41774L5.73029 8.68003L8.68123 5.72909L9.41895 6.4668Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_1104_43670">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];
