import { Box } from "@mui/material";
import { keyframes } from "@emotion/react";

// Define the keyframes for the loader animation
const loaderAnimation = keyframes`
  33% {
    background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%;
  }
  50% {
    background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%;
  }
  66% {
    background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%;
  }
`;

// Loader component
const PrimaryLoading = () => {
  return (
    <Box sx={loaderContainerStyle}>
      <Box sx={{ ...loaderBoxStyle, animationDelay: "0s" }} />
    </Box>
  );
};

export default PrimaryLoading;

// Styles
const loaderContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "auto", // Adjust this as needed
};

const loaderBoxStyle = {
  width: "40px",
  aspectRatio: "4",
  "--_g": "no-repeat radial-gradient(circle closest-side,#7C4CE1 90%,#0000)",
  background: `
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%
  `,
  backgroundSize: "calc(100%/3) 100%",
  animation: `${loaderAnimation} 1s infinite linear`,
  margin: "0 4px",
};
