import http from "../../services/http.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";

interface getUserDetailsResponse {
  message: string;
  user: any;
}
interface updateUserDetailsResponse {
  message: string;
  user: any;
}

export const getUserDetails = createAsyncThunk<
  getUserDetailsResponse,
  null,
  { rejectValue: any }
>("user/profile", async (_, thunkAPI) => {
  const token = store.getState().auth.auth.accessToken;
  const [response, error] = await http.get(`/user/profile`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
  return response.data;
});

export const updateUserDetails = createAsyncThunk<
  updateUserDetailsResponse,
  {
    gender: string;
    dateOfBirth: string;
    contactNumber: string;
    country: string;
    profession: string;
    email: string;
    fullName: string;
    preferredLanguage: string;
    profileImage?: File;
  },
  { rejectValue: any }
>(
  "updateuser/profile",
  async (
    data: {
      gender: string;
      dateOfBirth: string;
      contactNumber: string;
      country: string;
      profession: string;
      email: string;
      fullName: string;
      preferredLanguage: string;
      profileImage?: File;
    },
    thunkAPI
  ) => {
    const formData = new FormData();
    // Append all form fields
    formData.append("gender", data.gender);
    formData.append("dateOfBirth", data.dateOfBirth);
    formData.append("contactNumber", data.contactNumber);
    formData.append("country", data.country);
    formData.append("profession", data.profession);
    formData.append("email", data.email);
    formData.append("fullName", data.fullName);
    formData.append("preferredLanguage", data.preferredLanguage);

    // Append the profile image file, if it exists
    if (data.profileImage) {
      formData.append("profileImage", data.profileImage);
    }

    const [response, error] = await http.patch(`/user/profile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const deleteUserDetails = createAsyncThunk<
  updateUserDetailsResponse,
  null,
  { rejectValue: any }
>("delete/profile", async (_, thunkAPI) => {
  const [response, error] = await http.delete(`/user/delete`);
  if (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
  return response.data;
});
