import { Box, Typography } from "@mui/material";
import { ForbiddenImage, LogoImage } from "@/assets/images";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@/redux/store";
import { setAppForbidden } from "@/redux/slices/servicesSlice";

const AccessForbidden = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate("/");
    dispatch(setAppForbidden(false));
  };
  const handleIconClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    navigate("/");
    dispatch(setAppForbidden(false));
  };

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Box sx={FirstBoxStyle}>
        <Box sx={ContainerStyle}>
          <Box sx={BoxStyle}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  height: { xs: "18.2px", sm: "23.4px", md: "26px" },
                  width: { xs: "89.6px", sm: "115.2px", md: "128px" },
                }}
              >
                <img
                  src={LogoImage}
                  alt="logo"
                  style={{ height: "100%", width: "100%", cursor: "pointer" }}
                  onClick={handleIconClick}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 56px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { xs: "column-reverse", sm: "row" },
          px: { xs: "6px", sm: "12px", md: "12px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-start" },
            alignItems: { xs: "center", sm: "flex-start" },
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              pt: "1rem",
            }}
            variant="h2"
          >
            {t("Services.Access_Forbidden")}
          </Typography>
          <Typography
            sx={{
              py: "1.2rem",
              width: { xs: "100%", sm: "70%" },
            }}
            variant="h6"
          >
            {t("Services.Access_Forbidden_description")}
          </Typography>
          <PrimaryButton
            text={t("Services.Access_Forbidden_btn")}
            onClick={handleButtonClick}
          />
        </Box>
        <Box
          sx={{
            height: "auto",
            width: { xs: "100%", sm: "24rem", md: "32rem" },
          }}
        >
          {" "}
          <img
            src={ForbiddenImage}
            alt="Forbidden"
            style={{ height: "auto", width: "100%" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AccessForbidden;

const ContainerStyle = {
  height: "54px",
  position: "sticky",
  top: 0,
  width: "100%",
};

const BoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  width: "100%",
  pr: "16px",
  pl: "12px",
};

const FirstBoxStyle = {
  backgroundColor: "#FFFFFF",
  borderBottom: "1px solid #E2E8F0",
  zIndex: 1200,
  minHeight: "20px",
  width: "100%",
  px: { xs: "0", sm: "6px", md: "12px" },
};
