import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ResponsiveNotifictionModel = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Check if the screen width is less than 786px
      if (window.innerWidth < 786) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };

    // Add event listener for resizing
    window.addEventListener("resize", handleResize);

    // Trigger resize event on component mount to check initial size
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "10px",
          padding: "12px",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" sx={{ fontWeight: "600" }}>
            Limited Functionality
          </Typography>

          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box textAlign="center">
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "400", py: "1rem" }}
          >
            For better performance and full functionality, please use the
            website on a laptop or a larger device.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ResponsiveNotifictionModel;
