import { createSlice } from "@reduxjs/toolkit";
import { deployProject } from "../thunk/deploymentThunk";

export interface DeploymentState {
  isLoading: boolean;
  isDeployedDone: boolean;
}

const initialState: DeploymentState = {
  isDeployedDone: false,
  isLoading: false,
};

const deploymentSlice = createSlice({
  name: "deployment",
  initialState,
  reducers: {
    setDeployed: (state, action) => {
      state.isDeployedDone = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deployProject.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deployProject.fulfilled, (state, { payload }) => {
      state.isDeployedDone = payload?.success;
      state.isLoading = false;
    });
    builder.addCase(deployProject.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const { setDeployed } = deploymentSlice.actions;

export default deploymentSlice.reducer;
