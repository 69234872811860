import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import PublicLayout from "@/layout/publicLayout";
import { useEffect, useState } from "react";

const PublicRoutes = () => {
  const [AuthPage, setAuthPage] = useState(false);
  const { accessToken } = useAppSelector((state) => state.auth.auth);
  const location = useLocation();
  useEffect(() => {
    if (
      location?.pathname === "/login" ||
      location?.pathname === "/signup" ||
      location?.pathname === "/forget-password"
    ) {
      setAuthPage(true);
    } else {
      setAuthPage(false);
    }
  }, [location?.pathname]);

  return accessToken ? (
    <Navigate to="/" />
  ) : (
    <PublicLayout AuthPage={AuthPage}>
      <Outlet />
    </PublicLayout>
  );
};

export default PublicRoutes;
