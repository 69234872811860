import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  resendPasswordResetOtp,
  resetPasswordOtpVerification,
} from "../thunk/authThunk";
import { emialOtpVerification } from "../thunk/otpThunk";

function handlePending(state: AuthState) {
  state.loading = true;
  state.error = null;
}

function handleFulfilled(state: AuthState) {
  state.loading = false;
  state.error = null;
}

function handleRejected(state: AuthState, action: any) {
  state.loading = false;
  state.error = action.payload;
}

export interface AuthState {
  loading: boolean;
  error: any;
}

const initialState: AuthState = {
  loading: false,
  error: null,
};

const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
    builder.addCase(resendPasswordResetOtp.pending, handlePending);
    builder.addCase(resendPasswordResetOtp.fulfilled, handleFulfilled);
    builder.addCase(resendPasswordResetOtp.rejected, handleRejected);

    builder.addCase(resetPasswordOtpVerification.pending, handlePending);
    builder.addCase(resetPasswordOtpVerification.fulfilled, handleFulfilled);
    builder.addCase(resetPasswordOtpVerification.rejected, handleRejected);

    builder.addCase(emialOtpVerification.pending, handlePending);
    builder.addCase(emialOtpVerification.fulfilled, handleFulfilled);
    builder.addCase(emialOtpVerification.rejected, handleRejected);
  },
});

// export const { logout } = authSlice.actions;
export default otpSlice.reducer;
