import { v4 as uuidv4 } from "uuid";
import http from "../../services/http.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface SignupData {
  fullName: string;
  email: string;
  password: string;
  collaborationId?: string; // Optional
  guestId?: string; // Optional
}

interface LoginResponse {
  isEmailVerified: boolean;
  token: string;
  message: string;
  device_id: string;
}
interface GuestUserResponse {
  isEmailVerified: boolean;
  token: string;
  message: string;
  device_id: string;
}

interface SignupResponse {
  user: any;
  token: string;
  message: string;
  device_id: string;
  isEmailVerified: boolean;
}

interface forgetPasswordResponse {
  message: string;
}
interface resetPasswordOtpVerificationResponse {
  message: string;
}
interface resendPasswordResetOtpResponse {
  message: string;
}
interface resetPasswordResponse {
  success: boolean;
  message: string;
}
interface LogoutResponse {
  message: string;
  success: boolean;
}
interface ChangePasswordResponse {
  message: string;
  success: boolean;
}

export const login = createAsyncThunk<
  LoginResponse,
  {
    email: string;
    password: string;
    collaborationId?: string;
    guestId?: string;
  },
  { rejectValue: any }
>(
  "auth/login",
  async (
    data: {
      email: string;
      password: string;
      collaborationId?: string;
      guestId?: string;
    },
    thunkAPI
  ) => {
    const device = uuidv4();
    const [response, error] = await http.post("/auth/login", data, {
      headers: {
        device,
      },
    });
    if (error) return thunkAPI.rejectWithValue(error.response.data);

    return {
      ...response.data,
      device_id: device, // Include the device_id in the response
    };
  }
);

//  signup
export const signup = createAsyncThunk<
  SignupResponse,
  SignupData,
  { rejectValue: any }
>("auth/signup", async (data: SignupData, thunkAPI) => {
  const device = uuidv4();
  const [response, error] = await http.post("/auth/register", data, {
    headers: {
      device,
    },
  });
  if (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
  return {
    ...response.data,
    device_id: device, // Include the device_id in the response
  };
});
// forget password thung
export const forgetPassword = createAsyncThunk<
  forgetPasswordResponse,
  { email: string },
  { rejectValue: any }
>("auth/forget-password", async (data: { email: string }, thunkAPI) => {
  const [response, error] = await http.post("/auth/forget-password", data);
  if (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
  return response.data;
});

export const resetPasswordOtpVerification = createAsyncThunk<
  resetPasswordOtpVerificationResponse,
  { otp: string; email: string },
  { rejectValue: any }
>(
  "auth/reset-password-otp-verification",
  async (data: { otp: string; email: string }, thunkAPI) => {
    const [response, error] = await http.post(
      "/auth/reset-password-otp-verification",
      data
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const resendPasswordResetOtp = createAsyncThunk<
  resendPasswordResetOtpResponse,
  { email: string },
  { rejectValue: any }
>(
  "auth/resend-password-reset-otp",
  async (data: { email: string }, thunkAPI) => {
    const [response, error] = await http.post(
      "/auth/resend-password-reset-otp",
      data
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.resposne.data);
    }
    return response.data;
  }
);

export const resetPassword = createAsyncThunk<
  resetPasswordResponse,
  { newPassword: string; email: string },
  { rejectValue: any }
>(
  "auth/reset-password",
  async (data: { newPassword: string; email: string }, thunkAPI) => {
    const [response, error] = await http.put("/auth/reset-password", data);
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const logout = createAsyncThunk<
  LogoutResponse,
  void,
  { rejectValue: any }
>("auth/logout", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const device_id = state.auth.auth.device_id;
  const [response, error] = await http.delete("/auth/logout", {
    headers: { device: device_id },
  });
  if (error) return thunkAPI.rejectWithValue(error.response.data);
  return response.data;
});

export const ChangePassword = createAsyncThunk<
  ChangePasswordResponse,
  { password: string; currentPassword: string },
  { rejectValue: any }
>(
  "auth/changePassword",
  async (data: { password: string; currentPassword: string }, thunkAPI) => {
    const [response, error] = await http.patch(`/auth/change-password`, data);
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);
// guest user
export const GuestUser = createAsyncThunk<
  GuestUserResponse,
  void,
  { rejectValue: any }
>("auth/login", async (_, thunkAPI) => {
  const device = uuidv4();
  const [response, error] = await http.post("/auth/continue-as-guest", null, {
    headers: {
      device,
    },
  });
  if (error) return thunkAPI.rejectWithValue(error.response.data);

  return {
    ...response.data,
    device_id: device, // Include the device_id in the response
  };
});
