import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PrimaryLoading from "@/components/loader/PrimaryLoading";

export default function CommentDialog(props) {
  const { open, handleClose, handleSubmit, deleteFlag, BtnText, CardText,isLoading } = props;



  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteFlag
              ? `Are you sure you want to delete the comment? It will not be
            recoverable.`
              : CardText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            disabled={isLoading}
            sx={{ height: "36px", width: "80px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              bgcolor: deleteFlag && "#ed625d",
              ":hover": deleteFlag && { bgcolor: "#ff3231" },
              height: "36px",
              width: deleteFlag ? "80px" : "140px",
            }}
            onClick={handleSubmit}
            autoFocus
            disabled={isLoading}
          >
            {isLoading ? <PrimaryLoading /> : deleteFlag ? "Delete" : BtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
