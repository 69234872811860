import { createSlice } from "@reduxjs/toolkit";

export interface ThemeState {
  server: boolean;
  appLoader: boolean;
  forbidden: boolean;
}

const initialState: ThemeState = {
  server: false,
  appLoader: false,
  forbidden: false,
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setServer: (state, action) => {
      state.server = action.payload;
    },
    setAppLoader: (state, action) => {
      state.appLoader = action.payload;
    },
    setAppForbidden: (state, action) => {
      state.forbidden = action.payload;
    },
  },
});

export const { setServer, setAppLoader, setAppForbidden } =
  servicesSlice.actions;

export default servicesSlice.reducer;
