import palette from "../palette";
import { useAppSelector } from "@/redux/store";

export default function MenuItem() {
  const mode = useAppSelector((state) => state.theme.mode);
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: palette(mode).common.black,
          "&:hover": {
            color: palette(mode).common.black,
            backgroundColor: mode === "dark" ? "#333333" : "#f5f5f5",
          },
        },
      },
    },
  };
}
