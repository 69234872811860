import { Box, Tooltip, useMediaQuery } from "@mui/material";

import { useAppDispatch, useAppSelector } from "@/redux/store";
import { setResponsivenessValue } from "@/redux/slices/toolsSlice";
import {
  DesktopScreenIcon,
  MobileScreenIcon,
  TabletScreenIcon,
} from "@/assets/images";

// Component for Responsiveness Buttons
const ResponsivenessComp = () => {
  const isSmallScreen = useMediaQuery("(min-width:786px)");
  const { responsivenessValue } = useAppSelector((state) => state?.tools);
  const dispatch = useAppDispatch();
  return (
    <>
      {isSmallScreen && (
        <Box sx={ratioBoxStyle}>
          {ratioMap.map((item, index) => (
            <Tooltip key={index} title={item?.name}>
              <Box
                sx={catogeryBoxStyle(index, responsivenessValue)}
                onClick={() => {
                  dispatch(setResponsivenessValue(item.id));
                }}
              >
                <img
                  src={item?.imgSrc}
                  alt={item?.name}
                  style={{ height: "20px", width: "20px" }}
                />
              </Box>
            </Tooltip>
          ))}
        </Box>
      )}
    </>
  );
};

export default ResponsivenessComp;

const ratioMap = [
  {
    name: "Desktop",
    imgSrc: DesktopScreenIcon,
    id: 0,
  },
  {
    name: "Tablet",
    imgSrc: TabletScreenIcon,
    id: 1,
  },
  {
    name: "Mobile",
    imgSrc: MobileScreenIcon,
    id: 2,
  },
];

const ratioBoxStyle = {
  display: { xs: "none", sm: "flex" },
  alignItems: "center",
  flexWrap: "wrap",
};

const catogeryBoxStyle = (index, values) => ({
  boxShadow: "none",
  bgcolor: index === values ? "#E2E8F0" : "transparent",
  height: "40px",
  width: "40px",
  borderRadius: "5px",
  transition: "-1s",
  mr: ".4rem",
  padding: "5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});
