import { MenuItem, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { useAppDispatch } from "@/redux/store";
import { setFilterTypeProjects } from "@/redux/slices/projectsSlice";
import { useTranslation } from "react-i18next";

const SelectAppFilter = () => {
  const dispatch = useAppDispatch();
  const [selectedValue, setSelectedValue] = useState("all"); // State to store selected value
  const { t } = useTranslation();
  const handleChange = (event) => {
    dispatch(setFilterTypeProjects(event.target.value));
    setSelectedValue(event.target.value);
  };
  return (
    <TextField
      id="outlined-select-currency"
      sx={{
        mx: "8px",
        minWidth: "140px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          height: "40px",
          fontSize: "14px",
          fontWeight: "400",
          paddingRight: "32px",
        },
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
        },
      }}
      value={selectedValue}
      onChange={handleChange}
      SelectProps={{
        IconComponent: KeyboardArrowDownIcon,
      }}
      select
      placeholder="Select"
    >
      {[
        { name: "Header.All", value: "all" },
        { name: "Header.FullStack", value: "Fullstack" },
        { name: "Header.StaticApp", value: "HTML/CSS" },
      ].map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{ fontSize: "14px", fontWeight: "400" }}
        >
          {t(option.name)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectAppFilter;
