import {
  FacebookIcon,
  GithubIcon,
  LinkedinIcon,
  LogoImage,
  TwitterIcon,
} from "@/assets/images";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        bgcolor: theme?.palette?.background?.default,
        minHeight: "80px",
        width: "100%",
        px: { xs: "1rem", sm: "3rem", md: "6rem", lg: "7rem" },
        py: "36px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {mapData?.map((item, index) => (
            <Link
              key={index}
              to={item?.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                height: "24px",
                width: "24px",
                marginRight: index !== mapData?.length - 1 ? "24px" : 0,
                cursor: "pointer",
                display: "inline-block",
              }}
            >
              <img
                loading="lazy"
                src={item?.imgSrc}
                alt={item?.title}
                style={{ height: "100%", width: "100%" }}
              />
            </Link>
          ))}
        </Box>
        <Box
          sx={{
            height: { xs: "18.2px", sm: "23.4px", md: "26px" },
            width: { xs: "89.6px", sm: "115.2px", md: "128px" },
            my: { xs: "24px", sm: "0" },
          }}
        >
          <img
            loading="lazy"
            src={LogoImage}
            alt="logo"
            style={{ height: "100%", width: "100%", cursor: "pointer" }}
            // onClick={() => {
            //   naviagte("/");
            // }}
          />
        </Box>
        <Typography variant="body1">
          {t("LandingPage.All_rights_reserved")}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;

const mapData = [
  { imgSrc: TwitterIcon, link: "https://x.com/Big_Entity", title: "twitter" },
  {
    imgSrc: FacebookIcon,
    link: "https://www.facebook.com/p/Big-Entities-100091126597506/",
    title: "facebook",
  },
  {
    imgSrc: LinkedinIcon,
    link: "https://www.linkedin.com/company/bigentities/",
    title: "linkedin",
  },
  {
    imgSrc: GithubIcon,
    link: "https://www.linkedin.com/company/bigentities/",
    title: "github",
  },
];
