import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  forgetPassword,
  login,
  signup,
  logout,
  resetPassword,
  ChangePassword,
} from "../thunk/authThunk";

function handlePending(state: AuthState) {
  state.loading = true;
  state.error = null;
}

function handleFulfilled(state: AuthState) {
  state.loading = false;
  state.error = null;
}

function handleRejected(state: AuthState, action: any) {
  state.loading = false;
  state.error = action.payload;
}

export interface AuthState {
  token: string | null;
  isAuth: boolean;
  loading: boolean;
  error: any;
  userVerified: boolean;
  device_id: string | null;
  accessToken: string | null;
  refreshToken: string | null;
}

const initialState: AuthState = {
  token: null,
  isAuth: false,
  loading: false,
  error: null,
  userVerified: false,
  device_id: null,
  accessToken: null,
  refreshToken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userVerificationFunction: (state, action) => {
      state.userVerified = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setDeviceId: (state, action) => {
      state.device_id = action.payload;
    },
    clearAuth: (state) => {
      state.token = null;
      state.isAuth = false;
      state.loading = false;
      state.error = null;
      state.device_id = null;
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
    builder.addCase(login.pending, handlePending);
    builder.addCase(login.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.isAuth = true;
      state.loading = false;
      state.userVerified = action.payload.isEmailVerified;
      state.device_id = action.payload.device_id;
    });
    builder.addCase(login.rejected, handleRejected);

    builder.addCase(signup.pending, handlePending);
    builder.addCase(signup.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.isAuth = true;
      state.loading = false;
      state.device_id = action.payload.device_id;
      state.userVerified = action.payload.isEmailVerified;
    });
    builder.addCase(signup.rejected, handleRejected);

    builder.addCase(forgetPassword.pending, handlePending);
    builder.addCase(forgetPassword.fulfilled, handleFulfilled);
    builder.addCase(forgetPassword.rejected, handleRejected);

    builder.addCase(logout.pending, handlePending);
    builder.addCase(logout.fulfilled, (state) => {
      state.isAuth = false;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(logout.rejected, handleRejected);

    builder.addCase(resetPassword.pending, handlePending);
    builder.addCase(resetPassword.fulfilled, handleFulfilled);
    builder.addCase(resetPassword.rejected, handleRejected);

    builder.addCase(ChangePassword.pending, handlePending);
    builder.addCase(ChangePassword.fulfilled, handleFulfilled);
    builder.addCase(ChangePassword.rejected, handleRejected);
  },
});

export const {
  userVerificationFunction,
  clearAuth,
  setAccessToken,
  setRefreshToken,
  setDeviceId,
} = authSlice.actions;
export default authSlice.reducer;
