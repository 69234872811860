import MainNavbar from "@/layout/navbar/mainNavbar";
import { Box, useTheme } from "@mui/material";
import Footer from "../home/components/Footer";
import PricingSection from "./components/PricingSection";

const PricingPage = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: "1920px",
        mx: "auto",
        bgcolor: theme?.palette?.background?.paper,
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1100,
        }}
      >
        <MainNavbar />
      </Box>
      <Box
        sx={{
          height: "calc(100vh -54px)",
          width: "100%",
          overflow: "scroll",
        }}
      >
        <Box sx={{ mx: { xs: "1rem", sm: "3rem", md: "6rem", lg: "7rem" } }}>
          <PricingSection />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default PricingPage;
