import { Button } from "@mui/material";
import React from "react";
import PrimaryLoading from "../loader/PrimaryLoading";

interface SecondaryBtnProps {
  title: string;
  isLoading?: boolean;
  imgSrc?: any;
  onClick?: () => void;
  minHeight?: string;
  style?: any;
  minWidth?: string;
  disabled?: boolean;
  custtomHeight?: string;
}

const SecondaryButton: React.FC<SecondaryBtnProps> = (props) => {
  const {
    style,
    title,
    isLoading,
    imgSrc,
    minHeight,
    onClick,
    minWidth,
    custtomHeight,
    disabled,
  } = props;
  return (
    <Button
      variant="contained"
      type="submit"
      disabled={isLoading || disabled}
      onClick={onClick}
      sx={{
        // mt: "2rem",
        ...style,
        minHeight: minHeight ? minHeight : "40px",
        minWidth: minWidth ? minWidth : "164px",
        height: custtomHeight ? custtomHeight : "40px",
        background: ` #7C4CE1 `,
        "& :hover": {
          background: ` linear-gradient(270.09deg, #7C4CE1 0.08%, #442A7B 154.68%)`,
        },
        "& :disabled": {
          background: ` linear-gradient(270.09deg, #7C4CE1 0.08%, #442A7B 154.68%)`,
        },
      }}
    >
      {isLoading ? (
        <PrimaryLoading />
      ) : imgSrc ? (
        <>
          <img
            src={imgSrc}
            alt="button-icon"
            style={{ height: "22px", width: "22px", marginRight: "10px" }}
          />
          {title}
        </>
      ) : (
        title
      )}
    </Button>
  );
};

export default SecondaryButton;
