import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import Privatelayout from "@/layout/privateLayout";

const PrivateRoutes = () => {
  const { accessToken } = useAppSelector((state) => state.auth.auth);
  const location = useLocation();
  const isAuthenticated = accessToken;

  return isAuthenticated ? (
    <Privatelayout location={location}>
      <Outlet />
    </Privatelayout>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
