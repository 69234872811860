import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { generateIFrameSource } from "../../utils/helpers.util";
import {
  changeTemplate,
  generateFullStackApp,
  generatePrompt,
  getgeneratedPrompt,
  getPromptCategory,
  SaveUpdatedCode,
  templateSuggestions,
  updateViaAIRegenerate,
  updateViaPrompt,
} from "../thunk/promptThunk";

function handleThemePending(state: ThemeState) {
  state.loading = true;
  state.error = null;
}

function handleThemeRejected(state: ThemeState, action: any) {
  state.loading = false;
  state.error = action.payload;
}

export interface ThemeState {
  loading: boolean;
  isloading: boolean;
  updatedLoading: boolean;
  updatedAILoading: boolean;
  error: any;
  projectId: string;
  response: {};
  iframeData: any;
  promptCategory: any;
  filesSrc: any;
  projectRole: string;
  projectName: string;
  fullStackData: any;
  websiteType: string;
  generatePrompt: string;
  projectDetails: any;
  updatedCode: string;
  saveProjectStatus: boolean;
  saveProjectLoader: boolean;
  templatesArray: any[];
}

const initialState: ThemeState = {
  loading: false,
  isloading: false,
  updatedLoading: false,
  updatedAILoading: false,
  error: null,
  projectId: null,
  response: null,
  iframeData: null,
  promptCategory: [],
  filesSrc: null,
  projectRole: "owner",
  projectName: "",
  fullStackData: "",
  websiteType: "static",
  generatePrompt: "",
  projectDetails: {},
  updatedCode: null,
  saveProjectStatus: true,
  saveProjectLoader: false,
  templatesArray: [],
};

const promptSlice = createSlice({
  name: "prompt",
  initialState,
  reducers: {
    setUpdatedCode: (state, action) => {
      state.iframeData = action.payload;
    },
    setUpdatedCodeFiles: (state, action) => {
      state.filesSrc = action.payload;
    },
    setWebisteType: (state, action) => {
      state.websiteType = action.payload;
    },
    setGeneratePrompt: (state, action) => {
      state.generatePrompt = action.payload;
    },
    setPropmtValues: (state) => {
      state.updatedCode = null;
    },
    setSaveProjectStatus: (state, action) => {
      state.saveProjectStatus = action.payload;
    },
    setPromptClear: (state) => {
      state.iframeData = null;
      state.projectDetails = {};
      state.projectName = "";
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ThemeState>) => {
    builder.addCase(generatePrompt.pending, handleThemePending);

    builder.addCase(generatePrompt.fulfilled, (state, action) => {
      state.projectId = action.payload.projectId;
      const convrtForIframe = generateIFrameSource(action?.payload?.response);
      state.iframeData = convrtForIframe;

      state.loading = false;
    });

    builder.addCase(generatePrompt.rejected, handleThemeRejected);
    // full stck
    builder.addCase(generateFullStackApp.pending, handleThemePending);

    builder.addCase(generateFullStackApp.fulfilled, (state, action) => {
      state.fullStackData = action?.payload?.payload?.response;
      state.loading = false;
    });

    builder.addCase(generateFullStackApp.rejected, handleThemeRejected);
    //  get teh generated prompt
    builder.addCase(getgeneratedPrompt.pending, handleThemePending);

    builder.addCase(getgeneratedPrompt.fulfilled, (state, action) => {
      const convrtForIframe = generateIFrameSource(
        action?.payload?.project?.response
      );
      state.filesSrc = action?.payload?.project?.response;
      state.projectDetails = action?.payload;
      state.iframeData = convrtForIframe;
      state.loading = false;
      state.projectRole = action?.payload?.project?.role;
      state.projectName = action?.payload?.project?.name;
    });
    builder.addCase(getgeneratedPrompt.rejected, handleThemeRejected);
    // get prompt category
    builder.addCase(getPromptCategory.pending, (state) => {
      state.isloading = true;
      state.error = null;
    });
    builder.addCase(getPromptCategory.fulfilled, (state, action) => {
      state.promptCategory = action.payload.categories;
      state.isloading = false;
    });
    builder.addCase(getPromptCategory.rejected, (state, action) => {
      state.isloading = false;
      state.error = action.payload;
    });
    //  Update the design via prompt
    builder.addCase(updateViaPrompt.pending, (state) => {
      state.updatedLoading = true;
    });
    builder.addCase(updateViaPrompt.fulfilled, (state, action) => {
      state.updatedCode = action?.payload?.modelOutput;
      state.updatedLoading = false;
    });
    builder.addCase(updateViaPrompt.rejected, (state) => {
      state.updatedLoading = false;
    });
    //  Update the design via AIRegenerate
    builder.addCase(updateViaAIRegenerate.pending, (state) => {
      state.updatedAILoading = true;
      state.error = null;
    });
    builder.addCase(updateViaAIRegenerate.fulfilled, (state, action) => {
      state.updatedCode = action?.payload?.modelOutput;
      state.updatedAILoading = false;
    });
    builder.addCase(updateViaAIRegenerate.rejected, (state) => {
      state.updatedAILoading = false;
    });

    builder.addCase(SaveUpdatedCode.pending, (state) => {
      state.saveProjectLoader = true;
      state.error = null;
    });
    builder.addCase(SaveUpdatedCode.fulfilled, (state) => {
      state.saveProjectLoader = false;
      state.error = null;
    });
    builder.addCase(SaveUpdatedCode.rejected, (state, action) => {
      state.saveProjectLoader = false;
      state.error = action.payload;
    });
    // suggest the tamplates
    builder.addCase(templateSuggestions.pending, handleThemePending);

    builder.addCase(templateSuggestions.fulfilled, (state, action) => {
      state.templatesArray = action?.payload?.templates;
      state.loading = false;
    });

    builder.addCase(templateSuggestions.rejected, handleThemeRejected);
    // chnage the suggested tamplates
    builder.addCase(changeTemplate.pending, handleThemePending);

    builder.addCase(changeTemplate.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(changeTemplate.rejected, handleThemeRejected);
  },
});

export const {
  setUpdatedCode,
  setUpdatedCodeFiles,
  setWebisteType,
  setGeneratePrompt,
  setPropmtValues,
  setSaveProjectStatus,
  setPromptClear,
} = promptSlice.actions;

export default promptSlice.reducer;
