import {
  setUpdatedCode,
  setUpdatedCodeFiles,
} from "@/redux/slices/promptSlice";
import dayjs from "dayjs";

export const hasStringPatternInKey = (
  obj: Object,
  stringPattern: string
): boolean | void => {
  const keys = Object.keys(obj);
  let doesExist = false;
  keys.forEach((key) => {
    if (key.endsWith(stringPattern)) return (doesExist = true);
  });
  return doesExist;
};

//Returns the first KEY of the object ending with the string pattern.
export const getKeyMatchingStringPattern = (
  obj: Object,
  stringPattern: string
): string => {
  return Object.keys(obj).find((key) => key.endsWith(stringPattern));
};

//Returns an array consisting of all KEYS of object ending with the string pattern.
export const getKeysMatchingStringPattern = (
  obj: Object,
  stringPattern: string
): string[] => {
  return Object.keys(obj).filter((i) => i.endsWith(stringPattern));
};

export const replaceCssHrefAndAncors = (
  htmlString: string,
  cssString: string
): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const link = doc.querySelector('link[rel="stylesheet"]') as HTMLLinkElement;
  const anchors = doc.querySelectorAll("a");
  const submitButtons = doc.querySelectorAll('button[type="submit"]');

  if (link) link.href = cssString;

  submitButtons.forEach((button) => {
    (button as HTMLButtonElement).type = "button";
  });

  anchors.forEach((anchor) => {
    (anchor as HTMLAnchorElement).href = "#";
  });

  return doc.documentElement.outerHTML;
};

export const replaceCssHref = (
  htmlString: string,
  cssString: string,
  htmlFileName: string
): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const submitButtons = doc.querySelectorAll('button[type="submit"]');

  const stylesheetsAll = Array.from(
    doc.querySelectorAll('link[rel="stylesheet"]')
  );

  stylesheetsAll.forEach((link: HTMLLinkElement) => {
    const intoArray = link.href.split(".");
    if (intoArray[intoArray?.length - 2].endsWith(htmlFileName))
      link.href = cssString;
  });

  submitButtons.forEach((button) => {
    (button as HTMLButtonElement).type = "button";
  });

  return doc.documentElement.outerHTML;
};

export const getBlobUrl = (code: string, type: string): string => {
  const blob = new Blob([code], { type });
  return URL.createObjectURL(blob);
};

export const makeSrcForIFrame = (obj: Object): string[] => {
  const sources: string[] = [];
  let htmlBlob: string = "";

  const htmlPages: string[] = getKeysMatchingStringPattern(obj, ".html");

  htmlPages.forEach((file: string) => {
    const fileName: string = file.split(".")[0];
    const cssFileName: string = `${fileName}.css`;
    if (obj[cssFileName]) {
      const cssBlob = getBlobUrl(obj[cssFileName], "type/css");
      const newHtmlDoc = replaceCssHrefAndAncors(obj[file], cssBlob);
      htmlBlob = getBlobUrl(newHtmlDoc, "text/html");
    } else {
      htmlBlob = getBlobUrl(obj[file], "text/html");
    }
    sources.push(htmlBlob);
  });

  return sources;
};

export function removeHoverStyles(css) {
  const hoverRegex = /[^{}]*\s*:[\s]*hover\s*{[^}]*}/g;
  const cleanedCss = css.replace(hoverRegex, "");
  return cleanedCss.replace(/\n{2,}/g, "\n").trim();
}

export const generateIFrameSource = (obj: Object): string => {
  let htmlBlob = "";

  const htmlString: string = getKeyMatchingStringPattern(obj, ".html");
  const cssString: string = getKeyMatchingStringPattern(obj, ".css");
  if (cssString) {
    const cssBlob: string = getBlobUrl(obj[cssString], "text/css");
    const newHtmlDoc = replaceCssHref(
      obj[htmlString],
      cssBlob,
      htmlString?.split(".")[0]
    );
    htmlBlob = getBlobUrl(newHtmlDoc, "text/html");
  } else {
    htmlBlob = getBlobUrl(obj[htmlString], "text/html");
  }

  return htmlBlob;
};

export const saveToLocalStorage = (key: string, value: any): void => {
  const serializedValue = JSON.stringify(value);
  localStorage.setItem(key, serializedValue);
};

export const getItemFromLocalStorage = (key: string) => {
  return JSON.parse(localStorage.getItem(key));
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const changeFontFamily = (
  filesSrc: { [key: string]: string },
  fontFamily: string, // Corrected type for fontFamily
  dispatch: any
) => {
  const parser = new DOMParser();

  const updatedFiles = Object.keys(filesSrc).reduce((acc, fileName) => {
    if (fileName.endsWith(".html")) {
      // Parse the HTML file
      const doc = parser.parseFromString(filesSrc[fileName], "text/html");

      // Find the <link> tag that contains the font reference
      let fontLink = doc.querySelector('link[href*="fonts.googleapis.com"]');

      // If a font link exists, update it
      if (fontLink) {
        if (fontFamily) {
          // Corrected template literal usage
          const newFontLink = `https://fonts.googleapis.com/css2?family=${fontFamily}&display=swap`;
          fontLink.setAttribute("href", newFontLink);
        }
      } else if (fontFamily) {
        // If no font link exists and a new font family is provided, create a new link
        fontLink = doc.createElement("link");
        fontLink.setAttribute("rel", "stylesheet");
        const newFontLink = `https://fonts.googleapis.com/css2?family=${fontFamily}&display=swap`;
        fontLink.setAttribute("href", newFontLink);
        doc.head.appendChild(fontLink);
      }

      // Serialize the updated HTML back to a string
      acc[fileName] = new XMLSerializer().serializeToString(doc);
    } else if (fileName.endsWith(".css")) {
      // Update the CSS file to use the new font family, including the body selector
      const cssContent = filesSrc[fileName];

      const updatedCssContent = fontFamily
        ? cssContent.replace(
            /font-family:\s*['"][^'"]*['"]/g,
            `font-family: '${fontFamily}'`
          )
        : cssContent;
      acc[fileName] = updatedCssContent;
    }

    return acc;
  }, {} as { [key: string]: string });

  // Dispatch updated code files
  dispatch(setUpdatedCodeFiles(updatedFiles));
  // Generate the updated iFrame source
  const updatedData = generateIFrameSource(updatedFiles);
  dispatch(setUpdatedCode(updatedData));

  return updatedData;
};

export const getAssetUrl = (path: string) => {
  const baseURL = import.meta.env.VITE_APP_BASE_URL_ASSETS;
  return path ? `${baseURL}${path}` : "";
};

export const changecolorPallets = (
  filesSrc: { [key: string]: string },
  colorPallets: {
    bodyColor: string;
    navbarColor: string;
    footerColor: string;
    buttonColor: string;
    textColor: string;
  },
  dispatch: any
) => {
  if (filesSrc) {
    const updatedFiles = Object.keys(filesSrc).reduce((acc, fileName) => {
      if (fileName.endsWith(".html")) {
        acc[fileName] = filesSrc[fileName];
      } else if (fileName.endsWith(".css")) {
        const cssContent = filesSrc[fileName];
        let updatedCssContent = cssContent;

        // Update CSS variables in :root
        updatedCssContent = updatedCssContent.replace(
          /(:root\s*\{[^}]+\})/g,
          (match) => {
            return match
              .replace(
                /--bg-color:\s*[^;]+;/g,
                `--bg-color: ${colorPallets.bodyColor};`
              )
              .replace(
                /--navbar-bg-color:\s*[^;]+;/g,
                `--navbar-bg-color: ${colorPallets.navbarColor};`
              )
              .replace(
                /--footer-bg-color:\s*[^;]+;/g,
                `--footer-bg-color: ${colorPallets.footerColor};`
              )
              .replace(
                /--primary-color:\s*[^;]+;/g,
                `--primary-color: ${colorPallets.buttonColor};`
              )
              .replace(
                /--text-color:\s*[^;]+;/g,
                `--text-color: ${colorPallets.textColor};`
              );
          }
        );
        acc[fileName] = updatedCssContent;
      }

      return acc;
    }, {} as { [key: string]: string });
    // Dispatch updated code files
    dispatch(setUpdatedCodeFiles(updatedFiles));

    // Generate the updated iFrame source
    const updatedData = generateIFrameSource(updatedFiles);
    dispatch(setUpdatedCode(updatedData));
    return updatedData;
  }
};

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return str;
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
};

export const dateCalculator = ({ date }) => {
  const currentDate = dayjs(); // Current date
  const updatedDate = dayjs(date); // Convert input date to dayjs

  // Calculate total difference in minutes
  const totalMinutes = currentDate.diff(updatedDate, "minute");

  let result = "";

  if (totalMinutes < 60) {
    // Less than an hour: show minutes
    result = `${totalMinutes} minutes ago`;
  } else if (totalMinutes < 1440) {
    // Less than a day: show hours
    const hours = Math.floor(totalMinutes / 60);
    result = `${hours} hours ago`;
  } else if (totalMinutes < 10080) {
    // Less than a week: show days
    const days = Math.floor(totalMinutes / 1440);
    result = `${days} days ago`;
  } else if (totalMinutes < 40320) {
    // Less than a month (4 weeks): show weeks
    const weeks = Math.floor(totalMinutes / 10080);
    result = `${weeks} weeks ago`;
  } else if (totalMinutes < 525600) {
    // Less than a year: show months
    const months = Math.floor(totalMinutes / 40320);
    result = `${months} months ago`;
  } else {
    // More than a year: show years
    const years = Math.floor(totalMinutes / 525600);
    result = `${years} years ago`;
  }
  return result;
};
