import {
  AlignCenter,
  AlignEnd,
  AlignJustify,
  AlignStart,
  BoldStyle,
  CenterLineStyle,
  CircleBorderRadiousIcon,
  DashedStyleIcon,
  DottedStyledIcon,
  FirstBorderRadiousIcon,
  HeadingFive,
  HeadingFour,
  HeadingOne,
  HeadingSix,
  HeadingThree,
  HeadingTwo,
  ItalicStyle,
  NoBorderRadiousIcon,
  NoStyleIcon,
  Paragraph,
  SecondBorderRadiousIcon,
  SolidStyleIcon,
  Span,
  UnderlineStyle,
} from "@/assets/images";
import { setUpdateHTMLDocument } from "@/redux/slices/toolsSlice";
import { v4 as uuidv4 } from "uuid";

export const getExistingFonts = (iframeDocument: Document) => {
  const linkElement = iframeDocument.querySelector(
    'link[href*="fonts.googleapis.com"]'
  );
  if (linkElement && linkElement.getAttribute("href")) {
    const href = linkElement.getAttribute("href") || "";
    const fontFamilies = href.match(/family=([^&]+)/g); // Match all `family=FontName`
    if (fontFamilies) {
      return fontFamilies.map((family) =>
        decodeURIComponent(family.replace("family=", ""))
      );
    }
  }
  return [];
};

export const applyFontsToIframe = (
  selectedFonts: string,
  iframe,
  formik,
  dispatch
) => {
  const iframeDocument =
    iframe.contentDocument || iframe.contentWindow?.document;
  if (!iframeDocument) return;
  const existingFonts = getExistingFonts(iframeDocument);
  const targetElement = iframeDocument.getElementById(
    formik?.values?.selectedElementId
  );
  const allFonts = [...new Set([...existingFonts, selectedFonts])];
  // Clear previous font link elements to avoid duplicates
  const newFontLink = `https://fonts.googleapis.com/css2?family=${allFonts.join(
    "&family="
  )}&display=swap`;

  iframeDocument.head
    .querySelectorAll('link[href*="fonts.googleapis.com"]')
    .forEach((link) => link.remove());
  const newLinkElement = iframeDocument.createElement("link");
  newLinkElement.rel = "stylesheet";
  newLinkElement.href = newFontLink;
  iframeDocument.head.appendChild(newLinkElement);
  if (targetElement) {
    const existingStyle = targetElement.getAttribute("style") || "";

    // Check if font-family is already applied
    if (!existingStyle.includes("font-family:")) {
      targetElement.setAttribute(
        "style",
        `${existingStyle} font-family: ${selectedFonts};`
      );
    } else {
      // If font-family exists, just replace it
      const updatedStyle = existingStyle.replace(
        /font-family:[^;]+;/,
        `font-family: ${selectedFonts};`
      );
      targetElement.setAttribute("style", updatedStyle);
    }
  }
  dispatch(setUpdateHTMLDocument(iframeDocument));
};

export function setDynamicStyle(styles = {}, iframe, formik, dispatch) {
  // If the element is passed as an ID, get the actual element
  const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
  const selectedElement = iframeDoc.getElementById(
    formik?.values?.selectedElementId
  );
  const existingStyle = selectedElement.getAttribute("style") || "";
  let newStyle = existingStyle;
  for (const [property, value] of Object.entries(styles)) {
    const regex = new RegExp(`${property}:[^;]+;`, "i");
    if (regex.test(newStyle)) {
      newStyle = newStyle.replace(regex, `${property}: ${value};`);
    } else {
      newStyle += ` ${property}: ${value};`;
    }
  }

  // Set the updated style back to the element
  selectedElement.setAttribute("style", newStyle.trim());
  dispatch(setUpdateHTMLDocument(iframeDoc));
}
export const AddBolditaicfunction = (
  newTag,
  iframe,
  selectedElementId,
  dispatch
) => {
  const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
  const selectedElement = iframeDoc.getElementById(selectedElementId);
  const uniqueId = uuidv4();
  const tags = ["b", "i", "strike", "u"];

  if (!selectedElement) return;

  let textContent = selectedElement.innerHTML;

  if (tags.includes(newTag)) {
    // Check if the element already has the tag
    const regex = new RegExp(`</?${newTag}[^>]*>`, "g");
    if (regex.test(textContent)) {
      // If the tag exists, remove it
      textContent = textContent.replace(regex, "");
      selectedElement.innerHTML = textContent;
    } else {
      // If the tag doesn't exist, wrap the content with the tag
      const wrappedText = `<${newTag} id="${uniqueId}">${textContent}</${newTag}>`;
      selectedElement.innerHTML = wrappedText;
    }
  } else {
    // For tags not in the 'b', 'i', 'strike', 'u' list, wrap the element with the new tag
    const newElement = iframeDoc.createElement(newTag);
    for (let attr of selectedElement.attributes) {
      newElement.setAttribute(attr.name, attr.value);
    }
    newElement.innerHTML = selectedElement.innerHTML;
    selectedElement.parentNode.replaceChild(newElement, selectedElement);
  }

  // Call setFieldValue to update the iframe document
  dispatch(setUpdateHTMLDocument(iframeDoc));
};

export const AddFormDetails = (
  newTag,
  iframe,
  selectedElementId,
  dispatch,
  email
) => {
  const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
  const selectedElement = iframeDoc.getElementById(selectedElementId);

  // Ensure the selected element is a form
  if (!selectedElement || selectedElement.tagName !== "FORM") return;

  // If the newTag is 'form', we modify the existing form
  if (newTag === "form") {
    // Set form action to mailto
    const submitButton = selectedElement.querySelector("button");

    if (submitButton) {
      // Check if button has 'type' attribute, and modify or add 'submit'
      if (submitButton.hasAttribute("type")) {
        submitButton.setAttribute("type", "submit");
      } else {
        submitButton.setAttribute("type", "submit");
      }
      selectedElement.setAttribute("action", `mailto:${email}`);
      selectedElement.setAttribute("method", "POST");
      selectedElement.setAttribute("enctype", "text/plain");
    }

    // Update the iframe document in the store
    dispatch(setUpdateHTMLDocument(iframeDoc));
  }
};

export const AlignMap = [
  { imgSrc: AlignStart, alt: "align start", value: "start" },
  { imgSrc: AlignCenter, alt: "align center", value: "center" },
  { imgSrc: AlignEnd, alt: "align end", value: "end" },
  { imgSrc: AlignJustify, alt: "align justify", value: "justify" },
];
export const StyleMap = [
  { imgSrc: BoldStyle, alt: "bold", value: "b" },
  { imgSrc: ItalicStyle, alt: "italic", value: "i" },
  { imgSrc: UnderlineStyle, alt: "underline", value: "u" },
  { imgSrc: CenterLineStyle, alt: "centerline", value: "strike" },
];
export const HeadingMap = [
  {
    imgSrc: HeadingOne,
    alt: "h1",
    value: "h1",
    fontSize: "32",
    fontWeight: "700",
  },
  {
    imgSrc: HeadingTwo,
    alt: "h2",
    value: "h2",
    fontSize: "24",
    fontWeight: "700",
  },
  {
    imgSrc: HeadingThree,
    alt: "h3",
    value: "h3",
    fontSize: "18.72",
    fontWeight: "700",
  },
  {
    imgSrc: HeadingFour,
    alt: "h4",
    value: "h4",
    fontSize: "16",
    fontWeight: "700",
  },
  {
    imgSrc: HeadingFive,
    alt: "h5",
    value: "h5",
    fontSize: "13.28",
    fontWeight: "700",
  },
  {
    imgSrc: HeadingSix,
    alt: "h6",
    value: "h6",
    fontSize: "10.72",
    fontWeight: "700",
  },
  {
    imgSrc: Span,
    alt: "span",
    value: "span",
    fontSize: "16",
    fontWeight: "400",
  },
  {
    imgSrc: Paragraph,
    alt: "p",
    value: "p",
    fontSize: "16",
    fontWeight: "400",
  },
];

export const BorderStyle = [
  { imgSrc: NoStyleIcon, alt: "no border", value: "none" },
  { imgSrc: SolidStyleIcon, alt: "solid", value: "solid" },
  { imgSrc: DashedStyleIcon, alt: "dashed", value: "dashed" },
  { imgSrc: DottedStyledIcon, alt: "dotted", value: "dotted" },
];
export const BorderRadiousMap = [
  { imgSrc: NoBorderRadiousIcon, alt: "no border", value: "0" },
  { imgSrc: FirstBorderRadiousIcon, alt: "8px", value: "7px" },
  { imgSrc: SecondBorderRadiousIcon, alt: "20px", value: "20px" },
  { imgSrc: CircleBorderRadiousIcon, alt: "50%", value: "50%" },
];
export const BoxShadowMap = [
  { value: "0px 1px 1px 1px rgba(0, 0, 0, 0.15)" },
  { value: "0px 1px 2px 1px rgba(0, 0, 0, 0.3)" },
  { value: "0px 2px 3px 1px rgba(0, 0, 0, 0.3)" },
  { value: "0px 3px 3px 1px rgba(0, 0, 0, 0.3)" },
  { value: "0px 4px 4px 1px rgba(0, 0, 0, 0.3)" },
];

export const fontOptions = [
  "Roboto",
  "Open Sans",
  "Lobster",
  "Montserrat",
  "Lato",
  "Playfair Display",
  "Poppins",
  "Raleway",
  "Aldrich",
  "Vibur",
  "Matemasie",
  "Teachers",
];

export const fontweight = [
  { value: "100" },
  { value: "200" },
  { value: "300" },
  { value: "400" },
  { value: "500" },
  { value: "600" },
  { value: "700" },
  { value: "800" },
  { value: "900" },
];
// 400 normal and 700 is bold

export function removePx(value) {
  if (typeof value === "string" && value.endsWith("px")) {
    return parseFloat(value.replace("px", ""));
  }
  return value;
}
