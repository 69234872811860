import http from "../../services/http.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface collaboratorRequestResponse {
  message: string;
}
interface collaboratorResendInviteResponse {
  message: string;
}
interface collaboratorRoleUpdateResponse {
  message: string;
}
interface acceptcolleboratoeRequestResponse {
  message: string;
}
interface getCollaboratorsListResponse {
  message: string;
  collaborators: any[];
}

export const collaboratorRequest = createAsyncThunk<
  collaboratorRequestResponse,
  { email: string; projectId: string; role: string },
  { rejectValue: any }
>(
  "collaborators/new",
  async (
    data: { email: string; projectId: string; role: string },
    thunkAPI
  ) => {
    const [response, error] = await http.post(
      `/collaborators/new/${data?.projectId}`,
      { email: data?.email, role: data?.role }
    );
    if (error) return thunkAPI.rejectWithValue(error.response.data);

    return response.data;
  }
);

export const getCollaboratorsList = createAsyncThunk<
  getCollaboratorsListResponse,
  { projectId: string },
  { rejectValue: any }
>("get/collaboratorsList", async (data: { projectId: string }, thunkAPI) => {
  const [response, error] = await http.get(`/collaborators/${data?.projectId}`);
  if (error) return thunkAPI.rejectWithValue(error.response.data);

  return response.data;
});

export const collaboratorRoleUpdate = createAsyncThunk<
  collaboratorRoleUpdateResponse,
  { collaboratorId: string; projectId: string; role: string },
  { rejectValue: any }
>(
  "collaborators/change-role",
  async (
    data: { collaboratorId: string; projectId: string; role: string },
    thunkAPI
  ) => {
    const [response, error] = await http.patch(
      `/collaborators/change-role/${data?.projectId}`,
      { collaboratorId: data?.collaboratorId, role: data?.role }
    );
    if (error) return thunkAPI.rejectWithValue(error.response.data);

    return response.data;
  }
);
// accept invitation when loggedin
export const acceptcolleboratoeRequest = createAsyncThunk<
  acceptcolleboratoeRequestResponse,
  { collaborationId: string },
  { rejectValue: any }
>(
  "collaborators/accept",
  async (data: { collaborationId: string }, thunkAPI) => {
    const [response, error] = await http.post("/collaborators/accept", data);
    if (error) return thunkAPI.rejectWithValue(error.response.data);

    return response.data;
  }
);
// resend
export const collaboratorResendInvite = createAsyncThunk<
  collaboratorResendInviteResponse,
  { projectId: string; collaboratorId: string },
  { rejectValue: any }
>(
  "collaborators/resend-invite",
  async (data: { projectId: string; collaboratorId: string }, thunkAPI) => {
    const [response, error] = await http.post(
      `/collaborators/resend-invite/${data?.projectId}`,
      { collaboratorId: data?.collaboratorId }
    );
    if (error) return thunkAPI.rejectWithValue(error.response.data);

    return response.data;
  }
);
