import { Box, Container, keyframes } from "@mui/material";
import { LogoWithOutTextImage } from "@/assets/images";

const SecondaryLoader = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        position: "relative", // Ensure the container is relative for absolute positioning inside
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: { xs: "100px", sm: "150px", md: "200px" },
          height: { xs: "100px", sm: "150px", md: "200px" },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={LogoWithOutTextImage}
            alt="Logo"
            style={{ width: "50%", height: "50%", objectFit: "contain" }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            border: "4px solid transparent",
            borderRadius: "50%",
            borderColor: "#5935A6 transparent",
            animation: `${rotate} 1s infinite linear`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "92%",
              height: "92%",
              border: "inherit",
              borderRadius: "50%",
              borderColor: "#7C4CE1 transparent",
              animation: "inherit",
              animationDuration: "0.5s",
              animationDirection: "reverse",
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default SecondaryLoader;

const rotate = keyframes`
  100% { transform: rotate(.5turn); }
`;
