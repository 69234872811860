//
import Card from "./Card";
import Paper from "./Paper";
import Button from "./Button";
import Tooltip from "./Tooltip";
import Backdrop from "./Backdrop";
import Typography from "./Typography";
// import TextField from './Textfield';
import { Theme } from "@mui/material";
import Container from "./Container";
import TextField from "./Textfield";
import MenuItem from "./MenuItem";
import Select from "./Select";
// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Card(theme),
    Paper(),
    Button(theme),
    Tooltip(theme),
    Backdrop(theme),
    Typography(theme),
    Container(),
    TextField(),
    MenuItem(),
    Select()
  );
}
