import palette from "../palette";
import { useAppSelector } from "@/redux/store";

export default function Select() {
  const mode = useAppSelector((state) => state.theme.mode);

  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          height: "40px",
          backgroundColor: mode === "dark" ? "#333333" : "#EAEDF1",
          borderRadius: "7px",
          border: "none",
          "&:hover": {
            backgroundColor: mode === "dark" ? "#444444" : "#EAEDF0",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
        icon: {
          color: palette(mode).common.black,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
        },
      },
    },
  };
}
