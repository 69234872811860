import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  addComment,
  deleteComment,
  getComments,
  patchComment,
} from "../thunk/commentThunk";

function handlePending(state: commentsState) {
  state.loading = true;
  state.error = null;
}

function handleFulfilled(state: commentsState) {
  state.loading = false;
  state.error = null;
}

function handleRejected(state: commentsState, action: any) {
  state.loading = false;
  state.error = action.payload;
}

export interface commentsState {
  loading: boolean;
  commentsList: any[];
  error: any;
  isLoading: boolean;
}

const initialState: commentsState = {
  loading: false,
  error: null,
  commentsList: [],
  isLoading: false,
};

const commentSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<commentsState>) => {
    builder.addCase(addComment.pending, handlePending);
    builder.addCase(addComment.fulfilled, handleFulfilled);
    builder.addCase(addComment.rejected, handleRejected);
    // get comments
    builder.addCase(getComments.pending, handlePending);
    builder.addCase(getComments.fulfilled, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.commentsList = action.payload.comments;
    });
    builder.addCase(getComments.rejected, handleRejected);

    builder.addCase(patchComment.pending, handlePending);
    builder.addCase(patchComment.fulfilled, handleFulfilled);
    builder.addCase(patchComment.rejected, handleRejected);

    builder.addCase(deleteComment.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteComment.fulfilled, (state) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(deleteComment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default commentSlice.reducer;
