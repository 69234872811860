import { Toaster } from "react-hot-toast";
import { ErrorBoundary } from "react-error-boundary";
import ThemeProvider from "./theme";
import { useAppDispatch, useAppSelector } from "./redux/store";
import AppRoutes from "./routes/Routes";
import { Suspense, useEffect } from "react";
import SecondaryLoader from "./components/loader/SecondaryLoader";
import { getUserDetails } from "./redux/thunk/userThunk";
import Maintance from "./components/maintance";
import CompletePageLoader from "./components/loader/CompletePageLoader";
import AuthModel from "./components/model/AuthModel";
import ResponsiveNotifictionModel from "./components/model/ResponsiveNotifictionModel";
import ForbiddenScreen from "./components/accessForbidden";

function App() {
  const mode = useAppSelector((state) => state.theme.mode);
  const direction = useAppSelector((state) => state.theme.direction);
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.auth.accessToken);

  useEffect(() => {
    if (token) {
      dispatch(getUserDetails());
    }
  }, [token]);

  return (
    <>
      <ThemeProvider mode={mode} direction={direction}>
        <ErrorBoundary fallback={<SecondaryLoader />}>
          <Suspense fallback={<SecondaryLoader />}>
            <AppRoutes />
            <CompletePageLoader />
            <Maintance />
            <AuthModel />
            <ForbiddenScreen />
            <ResponsiveNotifictionModel />
          </Suspense>
        </ErrorBoundary>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            error: {
              duration: 5000,
            },
          }}
        />
      </ThemeProvider>
    </>
  );
}

export default App;
