import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import { GlobalFontIcon } from "@/assets/images";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  setFontFamily,
  setOpenGlobalFontFamily,
  setOpenGlobalPallets,
  setOpenGlobalTheme,
} from "@/redux/slices/toolsSlice";
import "./FontFamily.module.css";
import { fontOptions } from "@/pages/siteBuilder/components/Tabs/TextEditorServices";
import { setProductionModel } from "@/redux/slices/userSlice";

export default function FontFamilyMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const { fontFamily, openGlobalFontFamily } = useAppSelector(
    (state) => state.tools
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(setOpenGlobalFontFamily(true));
    dispatch(setProductionModel(false));
    dispatch(setOpenGlobalPallets(false));
    dispatch(setOpenGlobalTheme(false));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "6px",
          borderRadius: "8px",
          height: "48px",
          width: "48px",
          backgroundColor: openGlobalFontFamily ? "#CED4DA" : "transparent",
          ":hover": { backgroundColor: "#CED4DA" },
          transition: "all .3s",
        }}
      >
        <img
          src={GlobalFontIcon}
          alt="Layout"
          style={{ marginRight: "6px", width: "24px", height: "24px" }}
        />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        {fontOptions.map((item, index) => (
          <MenuItem
            key={index}
            sx={{
              fontFamily: `${item}`,
              backgroundColor:
                fontFamily === item ? "rgba(145, 158, 171, 0.2)" : "none",
            }}
            onClick={() => {
              dispatch(setFontFamily(item));
              setAnchorEl(null);
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
