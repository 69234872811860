const promisify = (promise: Promise<any>): Promise<[any, any]> => {
  return new Promise((resolve) => {
    promise
      .then((data) => resolve([data, null]))
      .catch((err) => {
        resolve([null, err]);
      });
  });
};

export default promisify;
