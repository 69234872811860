import { useNavigate } from "react-router-dom";
import { logout } from "../redux/thunk/authThunk";
import { useAppDispatch } from "../redux/store";
import { clearAuth } from "../redux/slices/authSlice";

export const useLogoutFunction = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutFunction = async () => {
    const resultAction = await dispatch(logout());
    if (logout.fulfilled.match(resultAction)) {
      localStorage.clear();
      dispatch(clearAuth());
      navigate("/login");
    }
  };

  return logoutFunction;
};
export const handleLogout = () => {
  const logoutFunction = useLogoutFunction();
  logoutFunction();
};
