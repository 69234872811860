import { Box, Divider, Tooltip, Typography, useTheme } from "@mui/material";
import PrivateNavbar from "./navbar/PrivateNavbar";
import React from "react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { LogoImage, LogoutIcons, LogoWithOutTextImage } from "@/assets/images";
import FontFamilyMenu from "./navbar/components/menu/FontFamilyMenu";
import ColorMenu from "./navbar/components/menu/ColorMenu";
import ThemeMenu from "./navbar/components/menu/ThemeMenu";
import ProfileModel from "./navbar/components/Model";
import { useNavigate } from "react-router-dom";
import AvatarText from "./navbar/components/AvatarText";
import { useLogoutFunction } from "@/hooks/Logout";
import LanguageDropdown from "@/components/languageDropdown";
import { useTranslation } from "react-i18next";
import { setReloadProjects } from "@/redux/slices/projectsSlice";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { setClearState, setGuestAuthModel } from "@/redux/slices/userSlice";

interface PrivatenavbarProps {
  children: React.ReactNode;
  customPadding?: string;
  location?: any;
}

const privateLayout: React.FC<PrivatenavbarProps> = (props) => {
  const { children, customPadding, location } = props;
  const mode = useAppSelector((state) => state.theme.mode);
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        bgcolor: mode === "dark" ? "#151c24" : "#fff",
        overflow: "hidden",
      }}
    >
      <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
        {/* small sidebar  */}
        {location.pathname.startsWith("/icod-builder") && <EditorLayout />}
        {(location.pathname.startsWith("/my-projects") ||
          location.pathname.startsWith("/profile") ||
          location.pathname.startsWith("/shared-projects")) && (
          <DashboardLayout location={location} dispatch={dispatch} />
        )}

        <Box sx={{ width: "100%", height: "100vh" }}>
          <PrivateNavbar location={location} />

          <Box
            sx={{
              height: "calc(100vh - 54px)",
              width: "100%",
              padding: customPadding ? customPadding : "0",
              overflow: "auto !important",
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default privateLayout;

const DashboardLayout = (props) => {
  const { location, dispatch } = props;
  const theme = useTheme();
  const ProfileData = useAppSelector((state) => state.user.user);
  const logoutHook = useLogoutFunction();
  const naviagte = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "280px",
        bgcolor: theme?.palette?.background?.paper,
        flexDirection: "column",
        display: { xs: "none", md: "flex" },
        border: `1px solid ${theme?.palette?.background?.neutral}`,
      }}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ height: "54px" }}>
          <Box
            sx={{
              width: "132px",
              height: "52.5px",
              mx: "14px",
              cursor: "pointer",
            }}
            onClick={() => {
              naviagte("/");
            }}
          >
            <img
              src={LogoImage}
              alt="logo"
              style={{ height: "100%", width: "100%" }}
            />
          </Box>
          <Divider />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ height: "100%", width: "100%", m: "12px" }}>
            {MapData.map((item, index) => (
              <Box
                key={index}
                onClick={() => {
                  naviagte(item?.url);
                  dispatch(setReloadProjects(true));
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "6px 8px",
                  borderRadius: "8px",
                  width: "100%",
                  mb: "6px",
                  transition: "all .3s",
                  backgroundColor:
                    location.pathname === item?.url ? "#E0DAF9" : "transparent",
                  "&:hover": {
                    backgroundColor: "#E0DAF9",
                    "& svg path": {
                      stroke: "#7e4be0",
                    },
                  },
                  "& svg path": {
                    stroke: location.pathname === item?.url && "#7e4be0",
                  },
                }}
              >
                {item?.SvgCustom}
                <Typography variant="h6" sx={{ fontWeight: "500", ml: "8px" }}>
                  {t(item?.name)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          p: "16px 8px ",
        }}
      >
        {ProfileData?.type === "guest" && (
          <Box
            sx={{
              border: "1px solid  rgba(223, 228, 234, 1)",
              borderRadius: "8px",
              padding: "16px",
              my: "12px",
            }}
          >
            <Typography variant="subtitle2">
              {t("utalities.You_are_currently")}
            </Typography>
            <PrimaryButton
              style={{ width: "100%", mt: "12px" }}
              compLeft={true}
              text={t("LandingPage.Login")}
              onClick={() => {
                dispatch(setGuestAuthModel(true));
              }}
            />
          </Box>
        )}
        <LanguageDropdown />
        <Divider sx={{ my: "12px" }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Logout">
            <img
              onClick={(e) => {
                e.preventDefault();
                logoutHook();
                dispatch(setClearState());
              }}
              src={LogoutIcons}
              alt="logout"
              style={{
                cursor: "pointer",
                width: "36px",
                height: "36px",
                marginRight: "6px",
              }}
            />
          </Tooltip>

          <AvatarText />
        </Box>
      </Box>
    </Box>
  );
};

const EditorLayout = () => {
  const naviagte = useNavigate();
  return (
    <Box sx={BoxSidebarStyle}>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={BoxLogoStyle}
          onClick={() => {
            naviagte("/my-projects");
          }}
        >
          <img
            src={LogoWithOutTextImage}
            alt="logo"
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
        <Box sx={BoxToolsStyle}>
          <FontFamilyMenu />
          <ColorMenu />
          <ThemeMenu />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <LanguageDropdown />
        <ProfileModel />
      </Box>
    </Box>
  );
};

const BoxSidebarStyle = {
  width: "60px",
  bgcolor: "#FFFFFF",
  flexDirection: "column",
  display: "flex",
  justifyContent: "space-between",
};
const BoxLogoStyle = {
  height: "34px",
  width: "32px",
  mx: "auto",
  my: "16px",
  cursor: "pointer",
};
const BoxToolsStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const MapData = [
  {
    name: "Dashboard.My_Projects",
    url: "/my-projects",
    SvgCustom: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1536_16403)">
          <path
            d="M4.16667 3.33337H7.5L10 5.83337H15.8333C16.2754 5.83337 16.6993 6.00897 17.0118 6.32153C17.3244 6.63409 17.5 7.05801 17.5 7.50004V14.1667C17.5 14.6087 17.3244 15.0327 17.0118 15.3452C16.6993 15.6578 16.2754 15.8334 15.8333 15.8334H4.16667C3.72464 15.8334 3.30072 15.6578 2.98816 15.3452C2.67559 15.0327 2.5 14.6087 2.5 14.1667V5.00004C2.5 4.55801 2.67559 4.13409 2.98816 3.82153C3.30072 3.50897 3.72464 3.33337 4.16667 3.33337Z"
            stroke="#374151"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1536_16403">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    name: "Dashboard.Shared_Projects",
    url: "/shared-projects",
    SvgCustom: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1536_22723)">
          <path
            d="M14.166 5.00004C14.166 5.44207 14.3416 5.86599 14.6542 6.17855C14.9667 6.49111 15.3907 6.66671 15.8327 6.66671C16.2747 6.66671 16.6986 6.49111 17.0112 6.17855C17.3238 5.86599 17.4993 5.44207 17.4993 5.00004C17.4993 4.55801 17.3238 4.13409 17.0112 3.82153C16.6986 3.50897 16.2747 3.33337 15.8327 3.33337C15.3907 3.33337 14.9667 3.50897 14.6542 3.82153C14.3416 4.13409 14.166 4.55801 14.166 5.00004Z"
            stroke="#374151"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.166 15C14.166 15.4421 14.3416 15.866 14.6542 16.1786C14.9667 16.4911 15.3907 16.6667 15.8327 16.6667C16.2747 16.6667 16.6986 16.4911 17.0112 16.1786C17.3238 15.866 17.4993 15.4421 17.4993 15C17.4993 14.558 17.3238 14.1341 17.0112 13.8215C16.6986 13.509 16.2747 13.3334 15.8327 13.3334C15.3907 13.3334 14.9667 13.509 14.6542 13.8215C14.3416 14.1341 14.166 14.558 14.166 15Z"
            stroke="#374151"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.5 10C2.5 10.4421 2.67559 10.866 2.98816 11.1786C3.30072 11.4911 3.72464 11.6667 4.16667 11.6667C4.60869 11.6667 5.03262 11.4911 5.34518 11.1786C5.65774 10.866 5.83333 10.4421 5.83333 10C5.83333 9.55801 5.65774 9.13409 5.34518 8.82153C5.03262 8.50897 4.60869 8.33337 4.16667 8.33337C3.72464 8.33337 3.30072 8.50897 2.98816 8.82153C2.67559 9.13409 2.5 9.55801 2.5 10Z"
            stroke="#374151"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.83398 10H8.33398L11.2507 15H14.1673"
            stroke="#374151"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.1673 5H11.2507L8.33398 10"
            stroke="#374151"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1536_22723">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];
