// ----------------------------------------------------------------------

import { Theme } from "@mui/material";
import palette from "../palette";

export default function Button(theme: Theme) {
  const mode = theme.palette.mode;
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          height: 48,
        },
        contained: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          boxShadow: theme.shadows[2],
          borderColor: theme.palette.primary.main,

          "&:hover": {
            backgroundColor: ` linear-gradient(270.09deg,${
              palette(mode).primary.main
            } #7C4CE1 0.08%,${palette(mode).primary.dark}  154.68%)`,
            boxShadow: theme.shadows[4],
            color: theme.palette.common.white,
            borderColor: palette(mode).primary.lighter,
          },
          "&:disabled": {
            color: theme.palette.common.white,
            // boxShadow: theme.shadows[8],
          },
          "&:hover:disabled": {
            backgroundColor: palette(mode).primary.lighter,
            color: theme.palette.common.white,
            boxShadow: theme.shadows[8],
          },
        },
        text: {
          color: "#000",
        },
        outlined: {
          color: theme.palette.common.black,
          borderColor: theme.palette.divider,
          borderWidth: 2,
          "&:hover": {
            borderWidth: 2,
          },
        },
      },
    },
  };
}
