import * as yup from "yup";

export const loginSchema = () =>
  yup.object({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  });

export const SignupSchema = () =>
  yup.object({
    fullName: yup.string().required("Full name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Must be a valid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Must be a valid email"
      ),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .test({
        name: "has-uppercase",
        message: "Password must contain at least one uppercase letter",
        test: function (value) {
          return /[A-Z]/.test(value);
        },
      })
      .test({
        name: "has-lowercase",
        message: "Password must contain at least one lowercase letter",
        test: function (value) {
          return /[a-z]/.test(value);
        },
      })
      .test({
        name: "has-number",
        message: "Password must contain at least one number",
        test: function (value) {
          return /\d/.test(value);
        },
      })
      .test({
        name: "has-special-char",
        message: "Password must contain at least one  special character",
        test: function (value) {
          return /[!@#$%^&*()_+]/.test(value);
        },
      }),
  });

export const ForgetPasswordSchema = () =>
  yup.object({
    email: yup
      .string()
      .required("Email is required")
      .email("Must be a valid email"),
  });
export const resestPasswordSchema = () =>
  yup.object().shape({
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .test({
        name: "has-uppercase",
        message: "Password must contain at least one uppercase letter",
        test: function (value) {
          return /[A-Z]/.test(value);
        },
      })
      .test({
        name: "has-lowercase",
        message: "Password must contain at least one lowercase letter",
        test: function (value) {
          return /[a-z]/.test(value);
        },
      })
      .test({
        name: "has-number",
        message: "Password must contain at least one number",
        test: function (value) {
          return /\d/.test(value);
        },
      })
      .test({
        name: "has-special-char",
        message: "Password must contain at least one  special character",
        test: function (value) {
          return /[!@#$%^&*()_+]/.test(value);
        },
      }),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Both password need to be the same")
      .required("Confirm password is required"),
  });

export const promptSchema = () =>
  yup.object({
    prompt: yup
      .string()
      .required("Prompt is required")
      .min(10, "Prompt must be at least 10 characters long"),
    projectName: yup.string().required("Project name is required."),
    categories: yup
      .array()
      .min(1, "Select at least 1 design styles")
      .max(1, "Select at most 1 design styles")
      .required("Design Style is required"),
  });

export const collaboratorsSchema = () =>
  yup.object({
    email: yup
      .string()
      .email("Must be a valid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Must be a valid email"
      ),
  });
export const editToolSchema = () =>
  yup.object({
    prompt: yup.string().required("Prompt is required"),
  });

export const ChangePasswordSchema = () =>
  yup.object().shape({
    currentPassword: yup.string().required("Current password is required"),
    newPassword: yup
      .string()
      .required("New password is required")
      .min(8, "Password must be at least 8 characters")
      .test({
        name: "has-uppercase",
        message: "Password must contain at least one uppercase letter",
        test: function (value) {
          return /[A-Z]/.test(value);
        },
      })
      .test({
        name: "has-lowercase",
        message: "Password must contain at least one lowercase letter",
        test: function (value) {
          return /[a-z]/.test(value);
        },
      })
      .test({
        name: "has-number",
        message: "Password must contain at least one number",
        test: function (value) {
          return /\d/.test(value);
        },
      })
      .test({
        name: "has-special-char",
        message: "Password must contain at least one  special character",
        test: function (value) {
          return /[!@#$%^&*()_+]/.test(value);
        },
      }),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], "Both password need to be the same")
      .required("Confirm password is required"),
  });

export const ProfileSchema = () =>
  yup.object({
    fullName: yup
      .string()
      .required("Full Name is required")
      .max(100, "Full Name cannot exceed 100 characters"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    gender: yup
      .string()
      .test(
        "is-gender-selected",
        "Please select a gender",
        (value) => value !== "none"
      )
      .required("Gender is required"),
    profession: yup
      .string()
      .test(
        "is-profession-selected",
        "Please select a profession",
        (value) => value !== "none"
      )
      .required("Profession is required"),
    country: yup
      .string()
      .test(
        "is-country-selected",
        "Please select a country",
        (value) => value !== "none"
      )
      .required("Country is required"),
    preferredLanguage: yup
      .string()
      .test(
        "is-language-selected",
        "Please select a language",
        (value) => value !== "none"
      )
      .required("Preferred Language is required"),
    contactNumber: yup.string().required("Contact number is required"),
    dateOfBirth: yup
      .date()
      .nullable()
      .typeError("Invalid date format")
      .required("Date of birth is required"),
  });

export const CreateAppSchema = () =>
  yup.object({
    prompt: yup
      .string()
      .required("Prompt is required")
      .min(10, "Prompt must be at least 10 characters long"),
    projectName: yup.string().required("Project name is required."),
  });
