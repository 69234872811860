import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ThemeState {
  mode: "light" | "dark";
  direction: "rtl" | "ltr";
}

const initialState: ThemeState = {
  mode: "light",
  direction: "ltr",
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setDirection: (state, action) => {
      state.direction = action.payload === "ar" ? "rtl" : "ltr";
    },
    setTheme: (state, action: PayloadAction<"light" | "dark">) => {
      state.mode = action.payload;
    },
  },
});

export const { toggleTheme, setTheme, setDirection } = themeSlice.actions;

export default themeSlice.reducer;
