import axios, { AxiosRequestConfig } from "axios";
import promisify from "../utils/promisify.util";
import toast from "react-hot-toast";

import { store } from "../redux/store";
import {
  clearAuth,
  setAccessToken,
  setRefreshToken,
} from "../redux/slices/authSlice";
import { setServer } from "@/redux/slices/servicesSlice";

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

const baseURL = import.meta.env.VITE_APP_BASE_URL_DEV;

let isRefreshing = false;
const retryRequestsQueue: RetryQueueItem[] = [];

const axiosAPI = axios.create({
  baseURL,
});

axiosAPI.interceptors.request.use(
  (config) => {
    store.dispatch(setServer(false));
    const language = store.getState().user.i18nextLng || "en";
    const accessToken = store.getState().auth.auth.accessToken;
    config.headers["Accept-Language"] = language;
    // Do not add Authorization header for login and signup requests
    const isAuthRequest =
      config.url?.includes("/auth/login") ||
      config.url?.includes("/auth/register");
    if (!isAuthRequest && accessToken) {
      config.headers["Authorization"] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosAPI.interceptors.response.use(
  (response) => {
    if (response.data.accessToken)
      store.dispatch(setAccessToken(response.data.accessToken));
    if (response.data.refreshToken)
      store.dispatch(setRefreshToken(response.data.refreshToken));
    return response;
  },

  async (error) => {
    let originalConfig: AxiosRequestConfig = error?.config;
    if (error.response && error.response.status === 401) {
      const refreshToken = store.getState().auth.auth.refreshToken;
      if (refreshToken) {
        if (!isRefreshing) {
          //Do not send refresh request if its already in progress.
          isRefreshing = true;
          const device = store.getState().auth.auth.device_id;
          try {
            const response = await axiosAPI.get(`/auth/refresh`, {
              headers: { "refresh-token": refreshToken, device },
            });
            store.dispatch(setAccessToken(response.data.accessToken));
            retryRequestsQueue.forEach(({ config, resolve, reject }) => {
              axiosAPI
                .request(config)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
            });
            retryRequestsQueue.length = 0;
            return axiosAPI(originalConfig);
          } catch (error) {
            if (error.response.status === 400) {
              toast.error("Session expired. Please login again.");
              localStorage.clear();
              store.dispatch(clearAuth());
            }
          } finally {
            isRefreshing = false;
          }
        }
      }

      return new Promise<void>((resolve, reject) => {
        retryRequestsQueue.push({ config: originalConfig, resolve, reject });
      });
    }
    if (!error.response) {
      toast.error("Cannot connect to the server. Please try again later.");
      store.dispatch(setServer(true));
    }

    return Promise.reject(error);
  }
);

const http = {
  get: (url = "", config = {}) => promisify(axiosAPI.get(url, config)),
  post: (url = "", data = {}, config = {}) =>
    promisify(axiosAPI.post(url, data, config)),
  put: (url = "", data = {}, config = {}) =>
    promisify(axiosAPI.put(url, data, config)),
  patch: (url = "", data = {}, config = {}) =>
    promisify(axiosAPI.patch(url, data, config)),
  delete: (url = "", config = {}) => promisify(axiosAPI.delete(url, config)),
};

export default http;

export function extractIdentifier(url) {
  const match = url.match(/https:\/\/([a-zA-Z0-9]+)\.icod\.ai/);
  return match ? match[1] : null;
}
