import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  deleteProject,
  getProjects,
  imagePost,
  prjectCoreFeatures,
  prjectTypeAndName,
  promptEnhancer,
  updateProjectName,
} from "../thunk/projectThunk";

function handlePending(state: ProjectState) {
  state.loading = true;
  state.error = null;
}

function handleRejected(state: ProjectState, action: any) {
  state.loading = false;
  state.error = action.payload;
}

export interface ProjectState {
  loading: boolean;
  isLoading: boolean;
  error: any;
  myProjects: any[];
  imageUrl: string;
  promptEnhancer: string;
  typeAndName: any;
  coreFeatures: any[];
  projectDetails: any;
  ProjectType: string;
  refreshWithoutLoader: boolean;
  projectSearch: string;
}

const initialState: ProjectState = {
  loading: false,
  isLoading: false,
  error: null,
  myProjects: [],
  imageUrl: "",
  typeAndName: null,
  coreFeatures: [],
  promptEnhancer: "",
  projectDetails: null,
  refreshWithoutLoader: true,
  ProjectType: "",
  projectSearch: "",
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setImageUrl: (state, action) => {
      state.imageUrl = action.payload;
    },
    setResetProject: (state) => {
      state.imageUrl = "";
    },
    setFilterProjects: (state, action) => {
      state.projectSearch = action.payload;
    },
    setFilterTypeProjects: (state, action) => {
      // If "AllApps" is selected, show all projects; otherwise, filter by `type`
      state.ProjectType = action.payload;
    },
    setReloadProjects: (state, action) => {
      state.refreshWithoutLoader = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ProjectState>) => {
    builder.addCase(getProjects.pending, handlePending);
    builder.addCase(getProjects.fulfilled, (state, action) => {
      const projectsWithNames = action?.payload?.projects?.map(
        (project: any) => ({
          ...project,
          name: project?.name || "Untitled",
        })
      );

      state.myProjects = projectsWithNames;
      state.projectDetails = action?.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getProjects.rejected, handleRejected);
    // image upload
    builder.addCase(imagePost.pending, handlePending);
    builder.addCase(imagePost.fulfilled, (state, action) => {
      state.imageUrl = action.payload.url;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(imagePost.rejected, handleRejected);
    // project name update
    builder.addCase(updateProjectName.pending, handlePending);
    builder.addCase(updateProjectName.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateProjectName.rejected, handleRejected);
    // get type and  name from Prompt
    builder.addCase(prjectTypeAndName.pending, handlePending);
    builder.addCase(prjectTypeAndName.fulfilled, (state, action) => {
      state.typeAndName = action?.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(prjectTypeAndName.rejected, handleRejected);
    // get core features from Prompt
    builder.addCase(prjectCoreFeatures.pending, handlePending);
    builder.addCase(prjectCoreFeatures.fulfilled, (state, action) => {
      state.coreFeatures = action?.payload?.response?.Features;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(prjectCoreFeatures.rejected, handleRejected);
    // get propmt enhancer from Prompt
    builder.addCase(promptEnhancer.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(promptEnhancer.fulfilled, (state, action) => {
      state.promptEnhancer = action?.payload?.response?.prompt;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(promptEnhancer.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    // Delete project
    builder.addCase(deleteProject.pending, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteProject.fulfilled, (state) => {
      state.loading = false;
      state.refreshWithoutLoader = false;
      state.error = null;
    });
    builder.addCase(deleteProject.rejected, (state) => {
      state.loading = false;
      state.error = null;
    });
  },
});

export const {
  setImageUrl,
  setResetProject,
  setFilterProjects,
  setFilterTypeProjects,
  setReloadProjects,
} = projectSlice.actions;

export default projectSlice.reducer;
