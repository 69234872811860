import http from "../../services/http.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface getProjectsResponse {
  message: string;
  projects: any[];
}
interface ImageUploadResponse {
  message: string;
  url: string;
  caption?: string;
}
interface updateProjectNameResponse {
  message: string;
  response: any;
}
interface prjectTypeAndNameResponse {
  message: string;
  response: any;
}
interface prjectCoreFeaturesResponse {
  message: string;
  response: any;
}
interface deleteProjectResponse {
  message: string;
  response: any;
}
interface promptEnhancerResponse {
  message: string;
  response: any;
}

export const getProjects = createAsyncThunk<
  getProjectsResponse,
  {
    shared: boolean;
    limit: Number;
    offset?: Number;
    type: String;
    name: String;
  },
  { rejectValue: any }
>(
  "project/all",
  async (
    data: {
      shared: boolean;
      limit: Number;
      offset?: Number;
      type?: String;
      name?: String;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.get(
      `/project/all?shared=${data.shared}&offset=${
        data.offset ? data.offset : 0
      }&limit=${data.limit ? data.limit : 10}&type=${
        data.type ? data.type : ""
      }&name=${data.name ? data.name : ""}`
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.resposne.data);
    }
    return response.data;
  }
);

export const imagePost = createAsyncThunk<
  ImageUploadResponse,
  { image: File; projectId: string },
  { rejectValue: any }
>(
  "upload/uploadImage",
  async (data: { image: File; projectId: string }, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("image", data.image);
      const [response, error] = await http.post(
        `/upload/uploadImage/${data?.projectId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }

      return response.data as ImageUploadResponse;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Error uploading image"
      );
    }
  }
);

export const updateProjectName = createAsyncThunk<
  updateProjectNameResponse,
  {
    projectName: string;
    projectId: string;
  },
  { rejectValue: any }
>(
  "updateProjectName",
  async (
    data: {
      projectName: string;
      projectId: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.patch(
      `/project//update/projectName/${data.projectId}`,
      { projectName: data.projectName }
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const prjectTypeAndName = createAsyncThunk<
  prjectTypeAndNameResponse,
  {
    prompt: string;
  },
  { rejectValue: any }
>(
  "project/prjectTypeAndName",
  async (
    data: {
      prompt: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.post(`/project/analyzePrompt`, data);
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const prjectCoreFeatures = createAsyncThunk<
  prjectCoreFeaturesResponse,
  {
    prompt: string;
  },
  { rejectValue: any }
>(
  "project/prjectCoreFeatures",
  async (
    data: {
      prompt: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.post(
      `/project/analyzePrompt/Features`,
      data
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);
export const promptEnhancer = createAsyncThunk<
  promptEnhancerResponse,
  {
    prompt: string;
  },
  { rejectValue: any }
>(
  "project/promptEnhancer",
  async (
    data: {
      prompt: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.post(`/project/promptEnhancer`, data);
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const deleteProject = createAsyncThunk<
  deleteProjectResponse,
  {
    projectId: string;
  },
  { rejectValue: any }
>(
  "project/deletetheproject",
  async (
    data: {
      projectId: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.delete(
      `/project/delete/${data.projectId}`
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);
