import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  acceptcolleboratoeRequest,
  collaboratorRequest,
  collaboratorResendInvite,
  collaboratorRoleUpdate,
  getCollaboratorsList,
} from "../thunk/collaboratorThunk";

function handlePending(state: collaboratorState) {
  state.loading = true;
  state.error = null;
}

function handleFulfilled(state: collaboratorState) {
  state.loading = false;
  state.error = null;
}

function handleRejected(state: collaboratorState, action: any) {
  state.loading = false;
  state.error = action.payload;
}

export interface collaboratorState {
  loading: boolean;
  isLoading: boolean;
  collaboratorList: any[];
  error: any;
}

const initialState: collaboratorState = {
  loading: false,
  isLoading: false,
  error: null,
  collaboratorList: [],
};

const collaboratorSlice = createSlice({
  name: "collaborator",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<collaboratorState>) => {
    builder.addCase(collaboratorRequest.pending, handlePending);
    builder.addCase(collaboratorRequest.fulfilled, handleFulfilled);
    builder.addCase(collaboratorRequest.rejected, handleRejected);
    //  get role list
    builder.addCase(getCollaboratorsList.pending, handlePending);
    builder.addCase(getCollaboratorsList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.collaboratorList = action.payload?.collaborators;
    });
    builder.addCase(getCollaboratorsList.rejected, handleRejected);
    // update role
    builder.addCase(collaboratorRoleUpdate.pending, handlePending);
    builder.addCase(collaboratorRoleUpdate.fulfilled, handleFulfilled);
    builder.addCase(collaboratorRoleUpdate.rejected, handleRejected);
    // accept invitation
    builder.addCase(acceptcolleboratoeRequest.pending, handlePending);
    builder.addCase(acceptcolleboratoeRequest.fulfilled, handleFulfilled);
    builder.addCase(acceptcolleboratoeRequest.rejected, handleRejected);
    // resend invitation
    builder.addCase(collaboratorResendInvite.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(collaboratorResendInvite.fulfilled, (state) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(collaboratorResendInvite.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default collaboratorSlice.reducer;
