import http from "../../services/http.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface addCommentResponse {
  message: string;
}
interface patchCommentResponse {
  message: string;
}
interface deleteCommentResponse {
  message: string;
}
interface patchCommentResponse {
  message: string;
}
interface getCommentsResponse {
  message: string;
  comments: any[];
}

export const addComment = createAsyncThunk<
  addCommentResponse,
  { text: string; projectId: string },
  { rejectValue: any }
>(
  "comments/new",
  async (data: { text: string; projectId: string }, thunkAPI) => {
    const [response, error] = await http.post(
      `/comments/new/${data?.projectId}`,
      { text: data?.text }
    );
    if (error) return thunkAPI.rejectWithValue(error.response.data);

    return response.data;
  }
);

export const getComments = createAsyncThunk<
  getCommentsResponse,
  { projectId: string },
  { rejectValue: any }
>("comments/get", async (data: { projectId: string }, thunkAPI) => {
  const [response, error] = await http.get(`/comments/${data?.projectId}`);
  if (error) return thunkAPI.rejectWithValue(error.response.data);

  return response.data;
});

export const patchComment = createAsyncThunk<
  patchCommentResponse,
  { text: string; projectId: string },
  { rejectValue: any }
>(
  "comments/edit",
  async (data: { text: string; projectId: string }, thunkAPI) => {
    const [response, error] = await http.patch(
      `/comments/edit/${data?.projectId}`,
      { text: data?.text }
    );
    if (error) return thunkAPI.rejectWithValue(error.response.data);

    return response.data;
  }
);
export const deleteComment = createAsyncThunk<
  deleteCommentResponse,
  { text: string; projectId: string },
  { rejectValue: any }
>(
  "comments/delete",
  async (data: { text: string; projectId: string }, thunkAPI) => {
    const [response, error] = await http.patch(
      `/comments/delete/${data?.projectId}`,
      { text: data?.text }
    );
    if (error) return thunkAPI.rejectWithValue(error.response.data);

    return response.data;
  }
);
