import {
  Box,
  Button,
  Dialog,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { collaboratorsSchema } from "@/utils/schema";
import PrimaryTextField from "@/components/textField/PrimaryTextField";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";

import toast from "react-hot-toast";
import {
  collaboratorRequest,
  getCollaboratorsList,
} from "@/redux/thunk/collaboratorThunk";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import CustomList from "./CustomList";
import { setGuestAuthModel } from "@/redux/slices/userSlice";
import { useTranslation } from "react-i18next";

const Collaborators = () => {
  const [open, setOpen] = React.useState(false);
  const { projectDetails } = useAppSelector((state) => state.prompt);
  const dispatch = useAppDispatch();
  const [tooltipMessage, setTooltipMessage] = useState("Copy link");
  const { id } = useParams();
  const { t } = useTranslation();
  // const { collaboratorList } = useAppSelector((state) => state.collaborator);
  const ProfileData = useAppSelector((state) => state.user.user);
  const { loading } = useAppSelector((state) => state.collaborator);

  const formik = useFormik({
    initialValues: {
      role: "admin",
      email: "",
    },
    validationSchema: () => collaboratorsSchema(),
    onSubmit: async (values) => {
      const data = {
        email: values.email.toLowerCase().trim(),
        projectId: id,
        role: values?.role,
      };
      const resultAction = await dispatch(collaboratorRequest(data));
      if (collaboratorRequest.fulfilled.match(resultAction)) {
        toast.success(
          resultAction?.payload?.message ||
            "Collaboration invitation send Successfully"
        );
      } else if (collaboratorRequest.rejected.match(resultAction)) {
        toast.error(resultAction?.payload?.reason || "Something went wrong!");
      }
      setOpen(false);
      formik.resetForm();
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    dispatch(getCollaboratorsList({ projectId: id }));
  }, []);

  const {
    errors,
    values,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    setFieldValue,
  } = formik;
  const handleChangeType = (event: SelectChangeEvent) => {
    setFieldValue("role", event.target.value);
  };
  const handleCopy = () => {
    const shareLink = `https://${projectDetails?.project?._id}.icod.ai/`;
    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        setTooltipMessage("Copied!"); // Update the tooltip message
        setTimeout(() => setTooltipMessage("Copy link"), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
        setTooltipMessage("Failed to copy");
        setTimeout(() => setTooltipMessage("Copy link"), 2000);
      });
  };

  return (
    <>
      <Box>
        <Button
          className="icod-share-and-collborator-button-toolbar-controls"
          variant="outlined"
          startIcon={<ShareIcon />}
          sx={{
            width: { xs: "100%", md: "102px" },
            borderColor: "#fff",
            color: "#49BC80",
            boxShadow: "0px 1px 4px 0px #00000029",
            ":hover": {
              color: "#49BC80",
              border: "2px solid #49BC80",
            },
          }}
          onClick={() => {
            if (ProfileData?.type === "guest") {
              dispatch(setGuestAuthModel(true));
            } else {
              handleClickOpen();
            }
          }}
        >
          {" "}
          {t("Header.Share")}{" "}
        </Button>
      </Box>
      <Dialog
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: "1rem",
            minWidth: { xs: "92%", sm: "400px", md: "500px" },
            boxShadow: "0px 0px 16px 2px rgba(124, 76, 225, 0.3)",
          },
        }}
        open={open}
      >
        <Box sx={InviteBoxStyle}>
          <Typography variant="h4">{t("utalities.Share_Project")}</Typography>
          <Button sx={ButtonStyle} onClick={handleClose}>
            <CloseIcon sx={{ color: "#7C4CE1" }} />
          </Button>
        </Box>

        {projectDetails?.project?.isDeployed && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PrimaryTextField
              title={t("utalities.Share_link")}
              placeholder={t("utalities.Share_link")}
              value={`https://${projectDetails?.project?._id}.icod.ai/`}
              name="Sharelink"
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <Box sx={{ pt: "24px", pl: "12px" }}>
              <Tooltip title={tooltipMessage} arrow>
                <Box sx={{ cursor: "pointer" }} onClick={handleCopy}>
                  {CopyIcon}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        )}

        <Box component={"form"} onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PrimaryTextField
              title={t("utalities.Email")}
              placeholder={t("utalities.Enter_a_email")}
              value={values?.email}
              name="email"
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched?.email}
              errors={errors.email}
            />
            {values.email && !errors.email && (
              <FormControl sx={{ mt: "12px", minWidth: "120px", ml: "12px" }}>
                <CustomSelect
                  value={values?.role}
                  onChange={handleChangeType}
                  displayEmpty
                  name="role"
                  sx={SelectStyle}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {MapData.map((item, index) => (
                    <MenuItem
                      key={index}
                      sx={{ height: "26px" }}
                      value={item?.value}
                    >
                      {t(item?.name)}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            )}
          </Box>

          {values.email && !errors.email && (
            <SecondaryButton
              title={t("utalities.Send_Invite")}
              minHeight={"36px"}
              style={{ width: "100%", mt: "1rem" }}
              isLoading={loading}
            />
          )}
          <CustomList
            emailValue={values.email}
            errorsValue={errors.email}
            id={id}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default Collaborators;

const MapData = [
  { name: "utalities.Viewer", value: "viewer" },
  { name: "utalities.Editor", value: "editor" },
  { name: "utalities.Admin", value: "admin" },
];

export const CustomSelect = styled(Select)(() => ({
  "&.MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#7E4FE2",
    },
  },
}));

const InviteBoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  mb: "1rem",
};
const ButtonStyle = {
  height: "36px",
  width: "36px",
  minWidth: "36px",
  bgcolor: "#F6F2FF",
};

const SelectStyle = {
  padding: "0",
  height: "45px",
  "&:hover": {
    borderColor: "#7c4ddd",
  },
};

const CopyIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2746_7444)">
      <path
        d="M7 9.667C7 8.95967 7.28099 8.28131 7.78115 7.78115C8.28131 7.28099 8.95967 7 9.667 7H18.333C18.6832 7 19.03 7.06898 19.3536 7.20301C19.6772 7.33704 19.9712 7.53349 20.2189 7.78115C20.4665 8.0288 20.663 8.32281 20.797 8.64638C20.931 8.96996 21 9.31676 21 9.667V18.333C21 18.6832 20.931 19.03 20.797 19.3536C20.663 19.6772 20.4665 19.9712 20.2189 20.2189C19.9712 20.4665 19.6772 20.663 19.3536 20.797C19.03 20.931 18.6832 21 18.333 21H9.667C9.31676 21 8.96996 20.931 8.64638 20.797C8.32281 20.663 8.0288 20.4665 7.78115 20.2189C7.53349 19.9712 7.33704 19.6772 7.20301 19.3536C7.06898 19.03 7 18.6832 7 18.333V9.667Z"
        stroke="#374151"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.012 16.737C3.70534 16.5622 3.45027 16.3095 3.27258 16.0045C3.09488 15.6995 3.00085 15.353 3 15V5C3 3.9 3.9 3 5 3H15C15.75 3 16.158 3.385 16.5 4"
        stroke="#374151"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2746_7444">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
