import { Box } from "@mui/material";
import { AuthBackgroundImage } from "../assets/images";
import React from "react";

interface PublicLayoutProps {
  children: React.ReactNode;
  AuthPage?: boolean;
}

const index: React.FC<PublicLayoutProps> = (props) => {
  const { children, AuthPage } = props;

  return (
    <Box
      sx={{
        backgroundImage: AuthPage && `url(${AuthBackgroundImage})`,
        bgcolor: !AuthPage && "#fff",
        height: AuthPage ? { xs: "100%", sm: "100vh" } : "100%",
        backgroundSize: AuthPage ? " 101% 101%" : "auto",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        overflow: "auto",
        mx: "auto",
      }}
    >
      <Box sx={BoxStyle(AuthPage)}>
        <Box sx={{ maxWidth: "1920px", width: "100%" }}>{children}</Box>
      </Box>
    </Box>
  );
};

export default index;

// styling

const BoxStyle = (AuthPage) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  height: AuthPage ? "100%" : { xs: "100%", sm: "82%" },
  p: "1.2rem .6rem",
  overFlow: "auto",
});
